import { api } from "../../api/api";
import { Spinner } from "../../assets/icons/Spinner";
import { Erroricon } from "../../assets/icons/Erroricon";
import { Link } from "react-router-dom";


/**
 * 
 * @param {{idProduct: number}} param0 
 * @returns 
 */
export const ProductList = ({ id, name }) => {
  const { data, isLoading, isError } = api.useGetItemByCategoryIdQuery(id);
  const Wrapper = ()=>{
    let i=0;
    let packed = [];
    let length = data.length + data.length%2;
    while(true){
      let unpacked = data.slice(i, i+(length/2));
      if(unpacked.length === 0) break;
      packed.push(unpacked);
      i+=length/2;
    }
    return packed.map((el, i, arr)=>{
      return <div key={i} className={`flex gap-1 flex-col items-${i===0?'end':'start'}`}>
          {
            el.map((item, index)=>{
              return <Link key={index} to={`/products/details/${item.id}`}>
                <img key={index}  width={(Math.random()*400)+200} height={(Math.random()*400)+200} 
                  className={`object-contain w-full md:w-[inherit] md:max-w-[40vw]`} 
                  src={item.version[0]?.image} alt="Cargando"></img>
              </Link>
            })
          }
      </div> 
    })
  }
  return isLoading?<Spinner/>:isError?<Erroricon></Erroricon>:(
      <div className="flex flex-col md:flex-row justify-center items-center w-full text-white pt-[7.56rem] gap-1">
        <Wrapper/>
      </div>
  );
};
