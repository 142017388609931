// import { useLocation } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import HOME_ICON from "../assets/icons/homeIcon.svg";
import VECTOR_ARROW from "../assets/icons/vectorArrow.svg";
import { _ } from "../i18n";


export const MapRoute = () => {
  const location = useLocation();
  var [currentPage] = location.pathname.match(`[a-zA-Z]+`);
  currentPage = currentPage[0].toUpperCase().concat(currentPage.slice(1))

  return (
    <>
      <div className="mt-[10rem] md:hidden">
      </div>
      <div className="hidden md:flex flex-row mt-[10rem] items-center md:pb-[3.5rem] 2xlpb-[6.44rem] ml-5 md:ml-inherit">
        <div className="flex gap-[0.69rem] items-center">
          <div>
            <img src={HOME_ICON} alt="HOME_ICON" />
          </div>
          <div className="font-averta md:text-base  2xl:text-[1.25rem] text-[#F4DE88] leading-[1.7825rem]">
            <Link to={'/'}>{_("Inicio")}</Link>
          </div>
        </div>
        <div className=" flex items-center">
          <div>
            <img
              className="h-[0.875rem] pl-[0.5rem]"
              src={VECTOR_ARROW}
              alt="VECTOR_ARROW"
            />
          </div>
          {currentPage?(
            <div className="pl-[0.75rem] font-averta md:text-base 2xl:text-[1.25rem] text-[#F4DE88] leading-[1.7825rem] cursor-default">
              <p>{_(currentPage)}</p>
            </div>
          ):null}
        </div>
      </div>
    </>
  );
};
