import React, { ReactElement } from "react"; // eslint-disable-line no-unused-vars


/**
 * 
 * @param {{children: ReactElement, className: String, style: React.CSSProperties, onClick:React.MouseEventHandler<HTMLDivElement>}} param0 
 * @returns 
 */
export const Title = ({ children, className, style, onClick }) => {
  var classes = "font-bold font-unna text-[#F4DE88] text-[2.5rem] xl:text-[3rem] 2xl:text-[4.375rem] font-normal";
  if(className) classes = classes.concat(' ', className);
  return (
    <div onClick={onClick} className={classes} style={{...style}}>
      {children}
    </div>
  );
};
