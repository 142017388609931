import { useParams } from "react-router-dom";
import { api } from "../api/api";
import { MapRoute } from "../components/MapRoute";
import { Spinner } from "../assets/icons/Spinner";
import { Carrousel } from "../layout/CollectionPage/CollectionCarousel";
import { CarouselRelatedProducts } from "../layout/ProductsDetails/CarouselRelatedProducts";
import { InfoCardWithTwoPhotos } from "../layout/General/InfoCardWithTwoPhotos";
import { Erroricon } from "../assets/icons/Erroricon";

export const ProductsDetailsPage = () => {
  const {id} = useParams();
  const {data, isLoading, isError, isSuccess, error} = api.useGetItemByIdQuery(id);

  return isLoading?<Spinner h={'screen'}></Spinner>:isError?<Erroricon h={'screen'} content={data?.error || error.error || error.status} ></Erroricon>:isSuccess?(
    <div className="bg-[#000] py-3">
      <div className="hidden md:block md:px-[14%]">
        <MapRoute />
      </div>
      <div className="flex flex-col md:flex-row md:justify-between md:pb-[8.6rem] md:pl-[13.5%] md:pr-[11%] ">
        <InfoCardWithTwoPhotos link={`/product/detail/${id}`} name={data.name} images={data.version?.map(({image})=>image)} id={data.id} description={data.description} />
      </div>
      <div>
        <Carrousel items={data.version} />
      </div>
      <div>
        <CarouselRelatedProducts category={data.category.id} exclude={data.id} />
      </div>
    </div>
  ):null
};
