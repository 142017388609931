import { InfoCardWhitBtn } from "../General/InfoCardWhitBtn";
import { api } from "../../api/api";
import { Erroricon } from "../../assets/icons/Erroricon";
import { Spinner } from "../../assets/icons/Spinner";


/**
 * 
 * @param {{items: Array}} param0 
 * @returns 
 */
function Subcategories({items, id}){
  return (
    <>
      {
      items.map(({name, version, description}, index)=>{
              if(index%2===0){
                return <div className="w-full" style={{display:'flex', flexDirection:'row'}} key={index}>
                  <img
                    className="w-1/2 object-cover aspect-square"
                    src={version[0]?.image}
                    alt={name}
                  />
                <InfoCardWhitBtn style={{wordWrap:'anywhere', wordBreak:'break-all'}} className="w-1/2" title={name} description={description} id={id} />
              </div>
              }else{
                return <div className="w-full" style={{display:'flex', flexDirection:'row'}} key={index}>
                  <InfoCardWhitBtn style={{wordWrap:'anywhere', wordBreak:'break-all'}}
                    className='w-1/2' title={name} description={description} id={id} />
                  <img
                    className="w-1/2 object-cover aspect-square"
                    src={version[0]?.image}
                    alt={name}
                  />
              </div>
            }
          }
        )
      }
    </>
  )
}

export const MainDesktop = () => {
  const { data, isLoading, isError, isFetching, isSuccess } = api.useGetCollectionQuery();
  return (
    <main className="bg-[#000]" style={{display:'flex', flexDirection:'column'}}>
        {
        (isFetching || isLoading) ?(
          <div className="flex items-center justify-center align-center">
            <Spinner />
          </div>
        ):isError?(
          <div className="flex items-center justify-center align-center">
            <Erroricon />
          </div>
        ):isSuccess?(data?.map(({id, image, name, items}, index) => {
          if(index%2===0){
            return <div style={{display:'flex', flexDirection:'row'}} key={index}> 
              <img draggable={false}
                className="w-1/2 object-cover aspect-square"
                src={image}
                alt={name}
              />
              <div className="w-1/2">
                <Subcategories id={id} items={items.slice(0, 2)} ></Subcategories>
              </div>
            </div>
          }else{
            return <div style={{display:'flex', flexDirection:'row'}} key={index}>
              <div className="w-1/2">
                <Subcategories id={id} items={items.slice(0, 2)} ></Subcategories>
              </div>
              <img draggable={false}
                className="w-1/2 object-cover aspect-square"
                src={image}
                alt={name}
              />
            </div>
          }
        })):null
        }
    </main>
  );
};
