import { Title } from "../../components/Title";

export const InfoCardsWhitThreePhotos = ({id, category, name, description, items}) => {
  return (
    <div className="grid py-[2.87rem] gap-[0.5rem] 2xl:gap-[1rem] 2xl:place-items-end">
      <div className="flex w-full ">
        <div className="flex">
          <Title>
            <h1 className="rotate-[270deg] translate-x-6 translate-y-3.5 xl:translate-y-6">
              {category}
            </h1>
          </Title>
        </div>
        {
          items[0]?.version[0]?.image?(
            <div className="flex w-full ">
              <img className="w-full" src={items[0]?.version[0]?.image} alt="FotoRandom" />
            </div>
          ):null
        }
      </div>
      {
        items[1]?.version[0]?.image?(
          <div className="flex w-full pl-[1.5rem] xl:pl-0 2xl:h-[21.5625rem]">
            <div className="flex w-full">
              <img className="w-[80%]" src={items[1]?.version[0]?.image} alt="FotoRandom" />
            </div>
          </div>
        ):null
      }
      {
        items[2]?.version[0]?.image?(
          <div className="flex w-full justify-end">
            <div className="flex w-full justify-end">
              <img className="w-[80%]" src={items[2]?.version[0]?.image} alt="FotoRandom" />
            </div>
          </div>
        ):null
      }
    </div>
  );
};
