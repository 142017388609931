import { ReactElement } from "react"; // eslint-disable-line no-unused-vars

/**
 * 
 * @param {{children: ReactElement, style: React.CSSProperties}} param0 
 * @returns 
 */
export const TextLight = ({ children, style, className }) => {
  return (
    <div style={style} className={`text-base text-[#F4DE88] font-averta font-thin xl:text-[1.3rem] 2xl:text-[1.625rem] ${className}`}>
      {children}
    </div>
  );
};


/**
 * 
 * @param {{children: ReactElement}} param0 
 * @returns 
 */
export const TextBold = ({ children }) => {
  return (
    <div className="text-base text-[#F4DE88] font-avertab font-normal xl:text-[1.3rem] 2xl:text-[1.75rem]">
      {children}
    </div>
  );
};
