import { TextLight } from "../../components/Texts";
import { Title } from "../../components/Title";

/**
 * 
 * @param {{
 *   id: any;
 *   title: any;
 *   description: any;
 *   image: any;
 * }} param0 
 * @returns 
 */
export const SectionCardLeftMobile = ({
  title,
  image,
  description,
}) => {
  return (
    <div>
      <div>
        <div className="pr-[1.25rem] mb-[1.5rem] ">
          <Title>
            <h1 className="text-[1.875rem] text-end">{title}</h1>
          </Title>
        </div>

        <div className="">
          <div className="flex">
            <img className="" src={image} alt={title} />
          </div>

          <div className="relative top-[-1.5rem] float-right py-[1.44rem] mr-[1.25rem] pl-[1.62rem] pr-[0.69rem] w-[17.6875rem] border border-[#F4DE88] bg-[#000] ">
            <TextLight>
              <p className="text-[1rem] leading-[1.6rem] font-averta">
                {description}
              </p>
            </TextLight>
          </div>
        </div>
      </div>
    </div>
  );
};
