import  { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BaseURL } from './apiConfig';


export const generalAPI = createApi({
  reducerPath: 'generalAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL
  }),
  endpoints: (builder) => {
    return  ({
      getBanners: builder.query({
        query: () => ({url:'/products/carrousel-banners/'})
      }),
      getGeneralData: builder.query({
        query: () => ({url:'/products/our-companies/'})
      })
  
    })
  }
})

export const { useGetBannersQuery, useGetGeneralDataQuery } = generalAPI