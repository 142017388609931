import { createSlice } from "@reduxjs/toolkit";


export const burgerMenuSlice = createSlice({
  name: "burgerMenu",

  initialState: {
    isOpen: false
  },

  reducers: {
      changeIsOpen: ( state ) => {
        return {...state, isOpen:!state.isOpen}
      }
  }
})

export const  { changeIsOpen } = burgerMenuSlice.actions
export default burgerMenuSlice.reducer