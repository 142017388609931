import { MapRoute } from "../components/MapRoute";
import { GalleryNameDesktop } from "../layout/GalleryDetails/GalleryNameDesktop";
import { GalleryNameMobile } from "../layout/GalleryDetails/GalleryNameMobile";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { api } from "../api/api";
import { CarouselGalleryDetails } from "../layout/GalleryDetails/CarouselGalleryDetails";
import { Spinner } from "../assets/icons/Spinner";
import { Erroricon } from "../assets/icons/Erroricon";
import { _ } from "../i18n";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export const GalleryDetailsPage = () => {
  const {id} = useParams();
  const [index, setIndex] = useState(null);
  const {data, isSuccess, isLoading, isError, error} = api.useGetGalleryQuery();

  useEffect(()=>{
    data?.forEach((gallery, index) => {
      if(parseInt(gallery.id) === parseInt(id)){
        setIndex(index);
      }
    });
  }, [data, id, isSuccess])

  return (
    <div className="min-h-screen bg-[rgb(0,0,0)] pt-[2%] px-[1.25rem] md:px-[13.7%] ">
      {isLoading?<Spinner h={'screen'}></Spinner>:isError?<Erroricon h={'screen'} content={data?.error || error.error || error.status}></Erroricon>:isSuccess && index !== null?(data.length > 0?(
            <>
              <MapRoute />
              <div className="hidden md:block">
                <GalleryNameDesktop
                  name={data[index]?.name}
                />
              </div>
              <div className="md:hidden block">
                <GalleryNameMobile
                  name={data[index]?.name}
                />
              </div>
              <Swiper
                className="m-0 w-100"
                style={{maxWidth:'100vw'}}
                modules={[Pagination, Navigation]}
                navigation={{
                  nextEl: ".nextElGallery",
                  prevEl: ".prevElGallery",
                }}
                initialSlide={index}
                onSlideChange={(swiper)=>{
                  setIndex(swiper.activeIndex)
                }}
                pagination={{
                  el: ".pagination",
                  clickable: true,
                }}
                slidesPerView={1}
              >
                      {
                        data.map(({images}, i)=>{
                          return <SwiperSlide key={i} className="object-contain z-0 d-flex text-center justify-center align-center">
                            <CarouselGalleryDetails index={index} images={images} />
                          </SwiperSlide>
                        })
                      }
              </Swiper>
            </>
          ):<Erroricon h={'screen'} content={_("No hay datos en el servidor")}></Erroricon>
      ):null}
    </div>
  );
};
