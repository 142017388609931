
import { ProviderRedux } from "./redux/ProviderRedux"
import { AppRouter } from "./routes/AppRouter"
// Export default App 
export default function App(props){
  return (
      <ProviderRedux>
        <AppRouter/>
      </ProviderRedux>
  )
}
