import { api } from "../api/api";
import { Erroricon } from "../assets/icons/Erroricon";
import { Spinner } from "../assets/icons/Spinner";
import { _ } from "../i18n";
import { CollectionCarousel } from "../layout/CollectionPage/CollectionCarousel";
import { BigInfoCardWhitBtn } from "../layout/General/BigInfoCardWhitBtn";


export const CollectionPage = () => {

  const {data, isLoading, isError, isFetching, isSuccess, error} = api.useGetCollectionPageQuery();
  return (isLoading || isFetching)?<Spinner h={'screen'}></Spinner>:isError?<Erroricon h={'screen'} content={data?.error || error.error || error.status}></Erroricon>:isSuccess?(
    <div className="bg-[#000] min-h-screen">
      <div className="h-screen xl:w-full">
        <img
          className="object-cover h-screen xl:h-full w-full"
          crossOrigin={'anonymous'}
          src={data.banner}
          alt={_('Cargando')}
        />
      </div>
      {
        data?.collections?.map(
          (collection, index)=>{
            return <div key={index} className="flex flex-col pt-[2.87rem] gap-y-[2rem]  ">
              <BigInfoCardWhitBtn id={collection.id} category={collection.category} description={collection.description} name={collection.name} image={collection.image} right={index%2!==0} />
              <div>
                <CollectionCarousel items={collection.items} />
              </div>
            </div>
          }
        )
      }
    </div>
  ):null
};
