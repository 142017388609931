import { Title } from "../../components/Title";
import { TextLight } from "../../components/Texts";
import { SeeMoreBtn } from "../../components/Btns/SeeMoreBtn";
import { Link } from "react-router-dom";
import { _ } from "../../i18n";

export const NewsPageMainMobile = ({news}) => {
  return (
    <div className="md:hidden">
      {news.map(({name, description, id, version}) => (
          <div key={id}>
            <div>
              <img crossOrigin="anonymous"
                className="w-full object-cover"
                src={version[0]?.image}
                alt="FOTO_BANNOTWO"
              />
            </div>
            <div className="px-[1.25rem] py-[1.88rem]">
              <div>
                <Title>
                  <h1>{name}</h1>
                </Title>
              </div>
              <div>
                <TextLight>
                  <p>{description}</p>
                </TextLight>
              </div>

              <div className="w-full pt-4">
                <div className="w-2/3">
                  <SeeMoreBtn link={`/products/details/${id}`} />
                </div>
              </div>
            </div>
          </div>
        ))}
      <div className="flex justify-center pb-6 pt-2 font-averta">
        <Link
          to={'/products/'}
          className="relative text-[#F4DE88] text-[1.4rem] font-[400] font-avertasemi h-[4.375rem] w-[25.375rem] text-center p-3 flex justify-center items-center"
        >
          {_("Ver más")}
          <svg className="absolute left-1/2 bottom-0" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path d="M10.0832 4.58291L10.0832 14.8221L5.60983 10.3487C5.25233 9.99124 4.66566 9.99124 4.30816 10.3487C3.95066 10.7062 3.95066 11.2837 4.30816 11.6412L10.349 17.6821C10.7065 18.0396 11.284 18.0396 11.6415 17.6821L17.6823 11.6412C18.0398 11.2837 18.0398 10.7062 17.6823 10.3487C17.3248 9.99124 16.7473 9.99124 16.3898 10.3487L11.9165 14.8221L11.9165 4.58291C11.9165 4.07874 11.504 3.66624 10.9998 3.66624C10.4957 3.66624 10.0832 4.07874 10.0832 4.58291Z" fill="#F4DE88"/>
          </svg>
        </Link>
      </div>
    </div>
  );
};
