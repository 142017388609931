import { InfoCardWhitBtn } from "../General/InfoCardWhitBtn";
import { Spinner } from "../../assets/icons/Spinner";
import { Erroricon } from "../../assets/icons/Erroricon";
import { api } from "../../api/api";
import React from "react";


function Subcategories({items, id}){
  return <div style={{marginTop:'10px', display:'block'}} >
    {items.length > 0 && items.map(({name, version, description}, index)=>{
    if(index%2===0){
      return <React.Fragment key={index}>
        <InfoCardWhitBtn title={name} description={description} id={id} />
        <img style={{float:'left'}} className="w-1/2 aspect-square object-cover " src={version[0]?.image} alt={name} />        
      </React.Fragment>
    }else{
      return <React.Fragment key={index}>
        <img style={{float:'right'}} className="w-1/2 aspect-square object-cover" src={version[0]?.image} alt={name} />
        <InfoCardWhitBtn title={name} description={description} id={id} />
      </React.Fragment>
    }
  })}
  <div style={{clear:"both", marginBottom:'5px'}}>{/* This is used to clear the previous floating layouts and dont overflow against any layout */}</div>
  </div>;
}

export const MainMobile = () => {
  const { data, isLoading, isError, isFetching, isSuccess } = api.useGetCollectionQuery();

  return (
    <main className="bg-[#000]">
      {
      isError?(
        <div className="felx items-center justify-center">
          <Erroricon />
        </div>
      ):(isLoading || isFetching)?(
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
      ):isSuccess? data?.map(({image, name, items, id}, index) => {
            return (
              <React.Fragment key={'main_mobile'.concat(index)}>
                <img
                  className="w-full object-cover xl:object-fill"
                  src={image}
                  alt={name}
                />
                <Subcategories id={id} items={items} ></Subcategories>
              </React.Fragment>
            );
        }): null
      }
    </main>
  );
};

