import { MapRoute } from "../components/MapRoute";
import { SectionCardRight } from "../layout/OurCompanyPage/SectionCardRight";
import { SectionCardLeft } from "../layout/OurCompanyPage/SectionCardLeft";
import { OurValuesSection } from "../layout/OurCompanyPage/OurValuesSection";
import { SectionCardRightMobile } from "../layout/OurCompanyPage/SectionCardRightMobile";
import { SectionCardLeftMobile } from "../layout/OurCompanyPage/SectionCardLeftMobile";
import {
  api
} from "../api/api";
import React from "react";
import { Erroricon } from "../assets/icons/Erroricon";
import { Spinner } from "../assets/icons/Spinner";

export const OurCompanyPage = () => {
  const { data, isError, isFetching, isLoading, isSuccess, error } = api.useGetAboutUsQuery();

  
  return (
    <div className="bg-[#000] min-h-screen flex flex-col gap-[2.63rem] pt-[2%] xl:px-[14%]">
      {isError?(
        <div className="flex items-center justify-center">
          <Erroricon h={'screen'} content={data?.error || error.error || error.status} />
        </div>
      ):(isFetching || isLoading)?(
        <div className="flex items-center justify-center">
          <Spinner h={'screen'} />
        </div>
      ):isSuccess?(
        <>
          <MapRoute />
          {
            data.subsections?.map(({id, title, image, content}, index) => {
              return (
                <div key={index}>
                  {(index % 2 === 0)?(
                    <React.Fragment key={index}>
                      <div className="hidden xl:block">
                        <SectionCardRight
                          id={id}
                          title={title}
                          image={image}
                          description={content}
                        />
                      </div>
                      <div className="xl:hidden">
                        <SectionCardRightMobile
                          id={id}
                          title={title}
                          image={image}
                          description={content}
                        />
                      </div>
                    </React.Fragment>
                  ):(
                    <React.Fragment key={index}>
                      <div className="hidden xl:block">
                        <SectionCardLeft
                          id={id}
                          title={title}
                          image={image}
                          description={content}
                        />
                      </div>
                      <div className="xl:hidden">
                        <SectionCardLeftMobile
                          id={id}
                          title={title}
                          image={image}
                          description={content}
                        />
                      </div>
                    </React.Fragment>
                  )}
                </div>
              );
            })
        }
        <OurValuesSection title={data.subsection_title} slots={data.slots} />
      </>
      ):null
    }
    </div>
  );
};
