import { Link } from "react-router-dom";
import { ArrowRight } from "../../assets/icons/ArrowRight";
import {_} from '../../i18n'

/**
 * 
 * @param {{link:string}} param0 
 * @returns 
 */
export const ContactBtn = ({ link, text }) => {

  return (
    <Link
      to={link}
      className={`px-5 py-1 flex justify-between items-center border-[2px] h-[2.875rem] border-[#F4DE88] bg-[#000000] text-[#F4DE88] transition duration-300 text-base xl:px-[2rem] xl:gap-5 xl:h-[3.2rem] 
        hover:bg-[#F4DE88] hover:text-[#000]`}
    >
      <div className="pr-2 font-semibold xl:text-[1.3rem] 2xl:text-[1.625rem]">
        <p>{text || _("Contáctanos")}</p>
      </div>
      <div className="fill-[#F4DE88]">
        <ArrowRight />
      </div>
    </Link>
  );
};
