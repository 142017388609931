import { TextBold, TextLight } from "../components/Texts";
import { Title } from "../components/Title";

import PHONE_ICON from "../assets/icons/phoneIcon.svg";
import MAIL_ICON from "../assets/icons/mailIcon.svg";
import LOCATION_ICON from "../assets/icons/locatioIcon.svg";
import { ContactForm } from "../components/ContactForm";
import { MapRoute } from "../components/MapRoute";
import { api } from "../api/api";
import { Spinner } from "../assets/icons/Spinner";
import { Erroricon } from "../assets/icons/Erroricon";
import {_} from '../i18n'
import React from "react";


export const ContactPage = () => { 
  const {data, isLoading, isError, error} = api.useGetContactQuery();

  return isLoading?<Spinner h={'screen'}/>:isError?<Erroricon h={'screen'} content={data?.error || error.error || error.status}></Erroricon>:(
      <div className="bg-[#000] pt-[2%] px-[1.25rem] xl:px-[14%] py-10">
        <MapRoute />
        <div className="flex flex-col  xl:h-screen xl:grid xl:grid-cols-2 xl:place-items-start ">
          <div className=" my-[1.6rem] xl:my-0 xl:gap-[1.06rem]">
            <div className=" flex relative top-0"></div>
            {/* TITLE */}
            <div>
              <div className="ml-1">
                <Title>
                  <h1 className="text-[1.875rem] xl:text-[3rem] 2xl:text-[4.375rem]">
                    {_('Contáctanos')}
                  </h1>
                </Title>
              </div>

              <div className="flex flex-col mt-[1.5rem] gap-[0.62rem] xl:flex xl:flex-col xl:mt-[4rem] xl:gap-[1.6rem]">
                <div className="flex gap-[0.56rem] items-center">
                  <div className="w-[1.3125rem] ml-1 2xl:w-[2.1875rem]">
                    <img src={PHONE_ICON} alt="PHONE_ICON" />
                  </div>
  
                  <TextLight>
                    <a href={`tel://${data.phone_number}`} className="leading-[26.4px] text-[1rem] 2xl:text-[1.5rem] 2xl:leading-[2.475rem]">
                      {data.phone_number}
                    </a>
                  </TextLight>
                </div>
  
                <div className="flex gap-[0.62rem] items-center">
                  <div className="w-[1.3125rem] ml-1 2xl:w-[2.1875rem]">
                    <img src={MAIL_ICON} alt="MAIL_ICON" />
                  </div>
  
                  <TextLight>
                    <a href={`mailto://${data.email}`} className="text-[1rem] leading-[26.4px] 2xl:text-[1.5rem] 2xl:leading-[2.475rem]">
                      {data.email}
                    </a>
                  </TextLight>
                </div>
  
                <div className="flex gap-[0.62rem] items-top">
                  <div className="min-w-[1.3125rem] max-w-[1.3125rem] ml-1 2xl:min-w-[2.1875rem] 2xl:max-w-[2.1875rem]">
                    <img src={LOCATION_ICON} alt="LOCATION_ICON" />
                  </div>
  
                  <TextLight>
                    <p className="text-[1rem] leading-[26.4px] 2xl:text-[1.5rem] 2xl:leading-[2.475rem]">
                      {data.address}
                    </p>
                  </TextLight>
                </div>
              </div>
            </div>
            {data.note?(
                <div className="flex flex-col ml-1 mt-[1.38rem] gap-[0.63rem] xl:gap-[1.38rem] xl:mt-[3.94rem]">
                  <div className="tracking-[-0.02rem] 2xl:tracking-[-0.0275rem]">
                    <TextBold>
                      <p className="leading-[26.4px] text-base 2xl:text-[1.375rem] 2xl:leading-[2.475rem]">
                        {data.note_title}
                      </p>
                    </TextBold>
      
                    <TextLight>
                      <div style={{whiteSpace:'pre'}} className="leading-[26.4px] text-base 2xl:text-[1.375rem] 2xl:leading-[2.475rem]">
                          {data.note}
                      </div>
                    </TextLight>
                  </div>
                  {data.whatsapp?(
                    <div className="flex  items-center gap-5 justify-start">
                      <div className="w-[1.3125rem] ml-1 xl:w-[2.1875rem]">
                        <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M29.0781 5.02344C32.1953 8.14062 34.125 12.2227 34.125 16.6758C34.125 25.7305 26.5547 33.1523 17.4258 33.1523C14.6797 33.1523 12.0078 32.4102 9.55859 31.1484L0.875 33.375L3.17578 24.8398C1.76562 22.3906 0.949219 19.5703 0.949219 16.6016C0.949219 7.54688 8.37109 0.125 17.4258 0.125C21.8789 0.125 26.0352 1.90625 29.0781 5.02344ZM17.4258 30.332C24.9961 30.332 31.3047 24.1719 31.3047 16.6758C31.3047 12.9648 29.7461 9.55078 27.1484 6.95312C24.5508 4.35547 21.1367 2.94531 17.5 2.94531C9.92969 2.94531 3.76953 9.10547 3.76953 16.6016C3.76953 19.1992 4.51172 21.7227 5.84766 23.9492L6.21875 24.4688L4.80859 29.5156L10.0039 28.1055L10.4492 28.4023C12.6016 29.6641 14.9766 30.332 17.4258 30.332ZM24.9961 20.0898C25.3672 20.3125 25.6641 20.3867 25.7383 20.6094C25.8867 20.7578 25.8867 21.5742 25.5156 22.5391C25.1445 23.5039 23.5117 24.3945 22.7695 24.4688C21.4336 24.6914 20.3945 24.6172 17.7969 23.4297C13.6406 21.6484 10.9688 17.4922 10.7461 17.2695C10.5234 16.9727 9.11328 15.043 9.11328 12.9648C9.11328 10.9609 10.1523 9.99609 10.5234 9.55078C10.8945 9.10547 11.3398 9.03125 11.6367 9.03125C11.8594 9.03125 12.1562 9.03125 12.3789 9.03125C12.6758 9.03125 12.9727 8.95703 13.3438 9.77344C13.6406 10.5898 14.5312 12.5938 14.6055 12.8164C14.6797 13.0391 14.7539 13.2617 14.6055 13.5586C13.8633 15.1172 12.9727 15.043 13.418 15.7852C15.0508 18.5312 16.6094 19.4961 19.0586 20.6836C19.4297 20.9062 19.6523 20.832 19.9492 20.6094C20.1719 20.3125 20.9883 19.3477 21.2109 18.9766C21.5078 18.5312 21.8047 18.6055 22.1758 18.7539C22.5469 18.9023 24.5508 19.8672 24.9961 20.0898Z" fill="#F4DE88"/>
                        </svg>
                      </div>
        
                      <TextLight>
                        <a className="text-base 2xl:text-[1.5rem]" href={data.whatsapp}>
                          {_('Contáctanos en')} WhatsApp
                        </a>
                      </TextLight>
                    </div>
                  ):null}
                </div>
              ):null
            }
          </div>
          <div className="mt-[0.37rem] xl:mt-[4.75rem] 2xl:mt-[9.5rem]">
            <ContactForm />
          </div>
        </div>
      </div>
    );
};
