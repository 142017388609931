import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomePage } from "../pages/HomePage";
import { Footer } from "../layout/Footer/Footer";
import { ContactPage } from "../pages/ContactPage";
import { OurCompanyPage } from "../pages/OurCompanyPage";
import { NewsPage } from "../pages/NewsPage";
import { GalleryPage } from "../pages/GalleryPage";
import { CollectionPage } from "../pages/CollectionPage";
import { ProductsPage } from "../pages/ProductsPage";
import { ProductsDetailsPage } from "../pages/ProductsDetailsPage";
import { GalleryDetailsPage } from "../pages/GalleryDetailsPage";
import { ProductsTwo } from "../pages/ProductsTwoPage";
import { CollectionPageTwo } from "../pages/CollectionPageTwo";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import TRHEELINE_MENU from "../assets/icons/threeLineIcon.svg";
import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Link as RouterLink, useNavigate
} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { SunoshIcon } from "../components/SunoshIcon";
import XICON from "../assets/icons/xIcon.svg";
import {_} from '../i18n'
import { api } from "../api/api";



const navItems  = {
  '/': _('Inicio'),
  '/about/ourcompany': _('Nuestra compañía'),
  '/about/contact': _('Contacto'),
  '/news': _('Nuevos'),
  '/gallery': _('Galería'),
  //'/gallery/details/:id':'',
  '/collection':_('Colección'),
  //'/collection/details/:id':'',
  // '/showroom':_('Sala de Exhibición'),
  '/products':_('Productos'),
  //'/products/details/:id':'',
  //'/product/detail/:id':''
};

function ListItemLink(props) {
  const { to, open, text, ...other } = props;

  let icon = null;
  if (open != null) {
    icon = open ? <ExpandLess /> : <ExpandMore />;
  }

  return (
    <li>
      <ListItem button component={RouterLink} to={to} {...other}>
        <ListItemText primary={text} />
        <span>{icon}</span>
      </ListItem>
    </li>
  );
}


export function RouterBreadcrumbs({text, options}) {
  const [open, setOpen] = React.useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <ListItemLink noClose={true} text={text} open={open} onClick={handleClick} >
      </ListItemLink>
      <Collapse component="li" in={open} timeout="auto" unmountOnExit>
        <List disablePadding>
          <ListItemLink text={'Test 1'} sx={{ pl: 4 }} to="/collection/details/1" />  
          <ListItemLink text={'Test 2'} sx={{ pl: 4 }} to="/collection/details/1" />
        </List>
      </Collapse>
    </>
  );
}



const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export function CollectionDropdown({to, text, collections}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    navigate('/collection')
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button sx={{ color:'#F4DE88', fontFamily:'font-averta font-extralight', fontWeight:'bold' }}
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {text}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
          'className':'bg-[#000] text-[#F4DE88]',
          'style':{border:'solid', borderWidth:'1.2px'}
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {/*<MenuItem onClick={handleClose} disableRipple>
            <RouterLink className="w-full h-full" to={to}>
              {_('Todas')}
            </RouterLink>
        </MenuItem>*/}
        {collections.map(({id, name}, index)=>{
          return <MenuItem key={index} onClick={handleClose} disableRipple>
            <RouterLink className="w-full h-full" to={`/collection/details/${id}`}>
              {name}
            </RouterLink>
          </MenuItem>
        })}
      </StyledMenu>
    </>
  );
}


export function Dropdown() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Options
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disableRipple>
          <EditIcon />
          Edit
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <FileCopyIcon />
          Duplicate
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleClose} disableRipple>
          <ArchiveIcon />
          Archive
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <MoreHorizIcon />
          More
        </MenuItem>
      </StyledMenu>
    </div>
  );
}

export function SwipeableTemporaryDrawer() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openCollections, setOpenCollections] = React.useState(false);
  const {data, isSuccess } = api.useGetCollectionQuery();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box className="font-averta font-extralight text-[1.125rem] text-[#F4DE88]" onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Divider className="bg-[#F4DE88]"  />
      <List>
        {Object.entries(navItems).map((item, index) => (
          item[0] === '/collection'?(
            <ListItem className="flex flex-col justify-start items-start w-full" key={index}>
              <div className="flex w-full">
                <RouterLink className="w-full" to={item[0]}>
                  {item[1]}
                </RouterLink>
                <span onClick={(event)=>{event.stopPropagation(); setOpenCollections(!openCollections);}}>
                  {openCollections ? <ExpandLess /> : <ExpandMore />}
                </span>
              </div>
              <Collapse className="w-full" component="div" in={openCollections} timeout="auto" unmountOnExit>
                <List disablePadding>
                  {
                    isSuccess?data?.map(({id, name}, index)=>{
                      return <ListItemLink key={index} text={name} sx={{ pl: 4 }} to={`/collection/details/${id}`} />  
                    }):null
                  }
                </List>
              </Collapse>
            </ListItem>
          ):(
              <ListItem key={index} disablePadding>
                <RouterLink to={item[0]}>
                  <ListItemButton sx={{ textAlign: 'center' }}>
                    <ListItemText primary={item[1]} />
                  </ListItemButton>
                </RouterLink>
              </ListItem>
            )
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window.document.body : undefined;
  

  return (
    <Box position={'absolute'} zIndex={'1000'} width={'100%'} sx={{ display: 'flex', maxWidth:'100vw', overflow:'hidden'}}>
      <CssBaseline  />
      <AppBar className="header" component="nav" position="relative" >
        <Toolbar className="flex justify-end items-center" style={{borderBottomColor:'#F4DE88', borderBottomStyle:'solid', borderBottomWidth:'1px'}}>
          <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, }}
            >
            <Button >
              <SunoshIcon no={1}/>
            </Button>
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon component={()=><img src={TRHEELINE_MENU} alt="Cargando"></img>} />
          </IconButton>
          
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {Object.entries(navItems).map((item, index) => (
                item[0]==='/collection'?(
                  isSuccess?<CollectionDropdown collections={data} key={index} to={item[0]} text={item[1]} ></CollectionDropdown>:null
                ):(
                  <Button key={index} sx={{ color:'#F4DE88', fontFamily:'font-averta font-extralight', fontWeight:'bold' }}>
                    <RouterLink to={item[0]} >
                      {item[1]}
                    </RouterLink>
                  </Button>
                )
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav className="header">
        <SwipeableDrawer anchor="right"
          onOpen={()=>null}
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100vw', backgroundColor:'black' },
          }}
        >
          <div className="flex justify-between">
            <Button>
              <SunoshIcon no={2} />
            </Button>
            <Button onClick={handleDrawerToggle}>
              <img src={XICON} alt="Cargando"/>
            </Button>
          </div>
          {drawer}
        </SwipeableDrawer>
      </nav>
      <Box className="header" component="main" sx={{ px: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  );
}


export const AppRouter = () => {
  return (
    <BrowserRouter>
      <SwipeableTemporaryDrawer></SwipeableTemporaryDrawer>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about/ourcompany" element={<OurCompanyPage />} />
        <Route path="/about/contact" element={<ContactPage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/gallery/details/:id" element={<GalleryDetailsPage />} />
        <Route path="/collection" element={<CollectionPage />} />
        <Route path="/collection/details/:id" element={<CollectionPageTwo />} />
        {/*<Route path="/showroom" element={<ShowRoomPages />} />*/}
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/products/:gallery" element={<ProductsPage />} />
        <Route path="/products/details/:id" element={<ProductsDetailsPage />} />
        <Route path="/product/detail/:id" element={<ProductsTwo />} />
      </Routes>
      <Footer />
      <ToastContainer theme="light" pauseOnHover ></ToastContainer>
    </BrowserRouter>
  );
};
