import { MapRoute } from "../components/MapRoute";
import { ProductList } from "../layout/ProductPage/ProductList";
import { Spinner } from "../assets/icons/Spinner";
import { Erroricon } from "../assets/icons/Erroricon";
import { api } from "../api/api";
import { Swiper, SwiperSlide} from "swiper/react";
import ArrowVector from "../assets/icons/vectorArrow.svg";
import { Navigation, Pagination } from "swiper/modules";
import { Title } from "../components/Title";
import { TextLight } from "../components/Texts";

export const ProductsPage = () => {
  const { data, isFetching, isLoading, isError, isSuccess, error } = api.useGetCategoryQuery();

  return (
    <div className="bg-[#000] pt-[2%] min-h-screen">
      {isSuccess?(
        (data.length > 0)?(
          <>
            <div className="hidden xl:block xl:px-[15%]">
              <MapRoute />
            </div>
            <Swiper
              className="mt-3"
              modules={[Pagination, Navigation]}
              slidesPerView={1}
              navigation={{
                nextEl: ".nextEl",
                prevEl: ".prevEl",
              }}
            >
              {data?.map(({id, name, description}, index) => {
                return (
                  <SwiperSlide key={index} className="z-0 h-full w-full">
                    <div className="flex flex-col justify-center px-[1.25rem] py-[1.88rem] pt-[6.12rem] xl:pt-0 xl:px-[15%]">
                      <div className="flex flex-row items-center justify-center gap-[0.82rem] xl:gap-[3rem]">
                        <button className={`prevEl items-center justify-center z-10`}>
                          <img
                            className={"w-[15px] aspect-square rotate-180"}
                            src={ArrowVector}
                            alt="ArrowVector"
                          />
                        </button>
                        <Title>
                          <h1 className="text-[1.5625rem] leading-normal xl:text-[4rem] 2xl:text-[6.25rem]">
                            {name}
                          </h1>
                        </Title>
                        <button
                          className={`nextEl items-center justify-center z-10`}
                        >
                          <img className="w-[15px] aspect-square"
                            src={ArrowVector}
                            alt="ArrowVector"
                          />
                        </button>
                      </div>
                      <div className="pt-[1rem]">
                        <TextLight>
                          <p className="text-center font-extralight xl:text-[1.3rem] xl:leading-[2rem] 2xl:text-[1.75rem] 2xl:leading-[2.4955rem]">
                            {description}
                          </p>
                        </TextLight>
                      </div>
                    </div>
                    <ProductList id={id} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </>
        ):(<div className="flex items-center justify-center" style={{minHeight:'100vh'}}>
        <Erroricon h={'screen'} content={'There is not data in the server right now'}  />
      </div>)
      ):isError?(
        <div className="flex items-center justify-center">
          <Erroricon h={'screen'} content={data?.error || error.error || error.status} />
        </div>
      ):(isFetching || isLoading)?(
        <div className="flex items-center justify-center">
          <Spinner h={'screen'} />
        </div>
      ):<div>Unknown status</div>}
    </div>
  );
};
