import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { BannerBtn } from "../../components/BannerBtn";
import GOLD_ARROW from "../../assets/icons/arrowBannerCarousel.svg";
import "swiper/css/navigation";
import "swiper/css";


export const CarouselBanner = ({carrousel}) => {
  var [context, setContext] = useState(carrousel[0]);
  return (
    <div className="flex flex-col w-full h-screen relative overflow-x-hidden">
      <Swiper
        className="m-0 w-100"
        style={{maxWidth:'100vw'}}
        modules={[Pagination, Navigation]}
        navigation={{
          nextEl: ".nextEl",
          prevEl: ".prevEl",
        }}
        onSlideChange={(swiper)=>{
          setContext(carrousel[swiper.activeIndex].categories[0])
        }}
        pagination={{
          el: ".pagination",
          clickable: true,
        }}
        slidesPerView={1}
      >
        {carrousel.map(({ image }, index) => {
          return <SwiperSlide key={index} className="object-cover z-0 d-flex text-center justify-center align-center">
              <img className="object-cover" style={{minWidth:'100vw', minHeight:'100vh'}} src={image} alt='Loading...' />
            </SwiperSlide>
          })}
      </Swiper>
      <button className="nextEl absolute top-1/2 right-0 z-10 w-[2.4375rem] xl:w-[3.25rem] xl:right-[15%]">
        <img className="rotate-180" src={GOLD_ARROW} alt="GOLD_LEFT_ARROW" />
      </button>

      <button className="prevEl absolute top-1/2 left-0 z-10 w-[2.4375rem] xl:w-[3.25rem] xl:left-[15%]">
        <img src={GOLD_ARROW} alt="GOLD_RIGHT_ARROW" />
      </button>
      <div className="pagination z-10 absolute left-1/2 bottom-[5%] "></div>

      <div className="flex items-end justify-center absolute top-0 pb-[5rem] left-0 h-screen w-screen z-[1] xl:pb-[10%]">
        {context?<BannerBtn title={context.related_text} sectionLink={context.related_url} icon={context.related_icon} />:null}
      </div>
    </div>
  );
};
