import { CarouselBanner } from "../layout/HomePage/CarouselBanner";
import { Title } from "../components/Title";
import { TextLight, TextBold } from "../components/Texts";
import { MainDesktop } from "../layout/HomePage/MainDesktop";
import { MainMobile } from "../layout/HomePage/MainMobile";
import { Spinner } from "../assets/icons/Spinner";
import { Erroricon } from "../assets/icons/Erroricon";
import { ContactBtn } from "../components/Btns/ContactBtn";
import { api } from "../api/api";


export const HomePage = () => {
  const { data, isLoading, isError, error } = api.useGetHomeQuery();
  return isLoading?(
      <Spinner h={'screen'}></Spinner>
    ):isError?(
      <Erroricon h={'screen'} content={data?.error || error.error || error.status} ></Erroricon>
    ):(
      <>
        <CarouselBanner carrousel={data.carrusel} />
        
        <section className="bg-[#000] flex flex-col text-center items-center px-[2.5rem] py-[2.5rem] gap-6 xl:px-[27%] xl:py-[11rem]">
          <Title>
            <h1>
              {data.subsection_title}
            </h1>
          </Title>
          <div className="flex flex-col gap-6 xl:gap-[3rem] xl:pb-[3rem]">
            <TextLight>
              <p className="xl:leading-[2rem] 2xl:leading-[2.5rem]">
                {data.subsection_content}     
              </p>
            </TextLight>

            <div className="px-[1.5rem]">
              <TextBold>
                <p className="xl:leading-[2rem] 2xl:leading-[2.5rem]">
                  {data.subsection_subtitle}
                </p>
              </TextBold>
            </div>
          </div>
          <ContactBtn link={data.subsection_action_url} text={data.subsection_action_text} />
        </section>
        <div className="hidden md:block ">
          <MainDesktop />
        </div>
        <div className="md:hidden">
          <MainMobile />
        </div>
      </>
  );
};
