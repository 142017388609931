import { useParams } from "react-router-dom";
import { CollectionCarousel } from "../layout/CollectionPage/CollectionCarousel";
import { InfoCardsWhitThreePhotos } from "../layout/CollectionPageTwo/InfoCardsWhitThreePhotos";
import { api } from "../api/api";
import { Spinner } from "../assets/icons/Spinner";
import { Erroricon } from "../assets/icons/Erroricon";
import { InfoCardWhitBtn } from "../layout/General/InfoCardWhitBtn";


export const CollectionPageTwo = () => {
  const {id} = useParams();
  const {data, isLoading,isError, isSuccess, error} = api.useGetCollectionByIdQuery(id);

  return isLoading?<Spinner h={'screen'}></Spinner>:isError?<Erroricon h={'screen'} content={data?.error || error.error || error.status}></Erroricon>:isSuccess?(
    <div className="bg-[#000] ">
      <div className="h-[21.875rem] w-full">
        <img
          className="object-cover h-full w-full"
          src={data.image}
          alt={data.name}
        />
      </div>
      <div className="flex flex-col md:flex-row md:justify-between  md:pl-[14%] md:pr-[10%] md:grid md:grid-cols-2 ">
        <div className="md:order-2">
          <InfoCardsWhitThreePhotos id={id} category={data.category?.name} name={data.name} description={data.description} items={data.items} />
        </div>
        <div className="md:order-1 md:flex md:w-full md:items-center">
          <InfoCardWhitBtn id={id} actionText={`Ver mas ${data.category?.name}`} disableLink={true} title={data.name} description={data.description} />
        </div>
      </div>
      <CollectionCarousel items={data.items}/>
    </div>
  ):null
};
