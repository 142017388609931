import { useState } from "react";
import SizeIcon from "../../assets/icons/SizeIcon";
import SizeIconBlack from "../../assets/icons/SizeIconBlack";
import VectorArrow from "../../assets/icons/VectorArrow";
import { Title } from "../../components/Title";
import { TextLight } from "../../components/Texts";
import { SwiperSlide, Swiper } from "swiper/react";
import { Controller, Navigation } from "swiper/modules";


export const DetailsSize = ({sizes, unit, controller}) => {
  const [isOpen, setisOpen] = useState(false);

  return sizes.length>0?(
    <>
      <div className={`absolute bottom-0 right-0 border-0  z-10 xl:hidden max-w-[30vw]`}>
        <div className={`flex transition-all w-[5rem] flex-col justify-between items-center ${isOpen?'h-[15rem]':'h-[3rem'} overflow-hidden`} >
          {isOpen?(
            <>
              <div onClick={() => setisOpen(!isOpen)} className="bg-[#F4DE88] w-full h-[4rem] rounded-ss-[2rem] cursor-pointer p-3 flex justify-center items-center">
                <SizeIconBlack />
              </div>
              <div className="bg-black w-full h-[11rem] flex flex-col text-center">
                <TextLight style={{fontSize:'medium'}}>{unit}</TextLight>
                <Swiper slidesPerGroup={5}
                  slidesPerView={5}
                  direction={'vertical'}
                  className="h-full text-center"
                  modules={[Navigation, Controller]}
                  controller={{control:controller}}
                  autoHeight={false}
                  navigation={{
                    nextEl: ".nextEl",
                    prevEl: ".prevEl",
                  }}
                >
                  {sizes.map(({width, height}, index)=>{
                    return <SwiperSlide onClick={()=>controller.slideTo(index)} key={index} className="z-0 overflow-hidden flex justify-center text-center"> 
                        <TextLight className='text-center font-medium cursor-pointer flex justify-center items-center w-100 m-auto px-3' key={index}>
                          {width}x{height}
                        </TextLight>
                      </SwiperSlide> 
                  })}
                </Swiper>
              </div>
            </>
          ):(
            <div onClick={() => setisOpen(!isOpen)} className="bg-black w-full rounded-ss-[2rem] p-3 cursor-pointer flex flex-col justify-between items-center ">
              <div className={`rotate-[270deg]`}>
                <VectorArrow />
              </div>
              <div>
                <SizeIcon />
              </div>
            </div>
          )
          }
        </div>
      </div>
      <div className="max-w-[30vw] hidden xl:flex items-center px-[3%] absolute bottom-0 right-0 bg-black md:h-[4rem] md:w-[35%] rounded-ss-[4rem] z-10 gap-[10px]">
        <Title style={{fontSize:'xx-large'}}>Tamaño({unit}): </Title>
        
        <div className="prevEl rotate-180">
          <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.42335 16.7012L20.774 16.7012L14.5044 22.9708C14.0034 23.4718 14.0034 24.2941 14.5044 24.7951C15.0055 25.2962 15.8149 25.2962 16.3159 24.7951L24.7824 16.3286C25.2835 15.8276 25.2835 15.0182 24.7824 14.5171L16.3159 6.05061C15.8149 5.54956 15.0055 5.54956 14.5044 6.05061C14.0034 6.55166 14.0034 7.36106 14.5044 7.86211L20.774 14.1317L6.42335 14.1317C5.71674 14.1317 5.1386 14.7098 5.1386 15.4164C5.1386 16.123 5.71674 16.7012 6.42335 16.7012Z" fill="#F4DE88"/>
          </svg>
        </div>
        <Swiper slidesPerGroup={1}
          slidesPerView={1}
          className="w-full"
          modules={[Navigation, Controller]}
          controller={{control:controller}}
          navigation={{
            nextEl: ".nextEl",
            prevEl: ".prevEl",
          }}
          breakpoints={{
            1250: {
              slidesPerView: 1,
            },
          }}
        >
          {sizes.map(({width, height}, index)=>{
            return <SwiperSlide key={index} className="z-0 text-center">
              <TextLight key={index} style={{fontSize:'large', cursor:'pointer'}}>{width}x{height}</TextLight>
          </SwiperSlide> 
          })}
        </Swiper>
        <div className="nextEl">
          <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.42335 16.7012L20.774 16.7012L14.5044 22.9708C14.0034 23.4718 14.0034 24.2941 14.5044 24.7951C15.0055 25.2962 15.8149 25.2962 16.3159 24.7951L24.7824 16.3286C25.2835 15.8276 25.2835 15.0182 24.7824 14.5171L16.3159 6.05061C15.8149 5.54956 15.0055 5.54956 14.5044 6.05061C14.0034 6.55166 14.0034 7.36106 14.5044 7.86211L20.774 14.1317L6.42335 14.1317C5.71674 14.1317 5.1386 14.7098 5.1386 15.4164C5.1386 16.123 5.71674 16.7012 6.42335 16.7012Z" fill="#F4DE88"/>
          </svg>
        </div>
      </div>
    </>
  ):null;
};
