import React from "react";
import { Provider } from "react-redux";
import { store } from "./store";


/**
 * 
 * @param {{children: React.ReactNode}} param0 
 * @returns 
 */
export const ProviderRedux = ({children}) => {
  return(
  <Provider store={store}>
    {children}
  </Provider>
  )}