import { Link } from "react-router-dom";
import { Title } from "./Title";


/**
 * 
 * @param {{image: string, name: string, id:number}} param0 
 * @returns 
 */
export const LinkCard = ({ image, name, id }) => {
  return (
    <Link
      to={`/gallery/details/${id}`}
      className="flex flex-col cursor-pointer text-center"
    >
      <div>
        <img
          className=" h-[13rem] object-cover xl:h-[17rem] w-full"
          src={image}
          alt={name}
        />
      </div>

      <div className=" w-full border border-[#F4DE88] xl:h-[4rem]">
        <Title>
          <h1 className="text-[1.5625rem] xl:text-[2rem] 2xl:text-[2.1875rem]">
            {name}
          </h1>
        </Title>
      </div>
    </Link>
  );
};
