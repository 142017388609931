import { Field, Form, Formik } from "formik";
import GOLD_RIGHT_ARROW from "../assets/icons/goldRightArrow.svg";
import { Spinner } from "../assets/icons/Spinner";
import { Erroricon } from "../assets/icons/Erroricon";
import { api } from "../api/api";
import { toast } from 'react-toastify';
import { useState } from "react";
import {_} from '../i18n'


export const ContactForm = () => {
  const [postMessageForm, { isLoading, isError }] = api.usePostContactMutation();
  var [contacted, setContacted] = useState(false);
  /**
   * 
   * @param {{name: string, lastName: string, message: string}} values 
   */
  const handleSubmit = async (values) => {
    await toast.promise(postMessageForm(values), _("Datos enviados, esperando por resultados!")).then((value)=>{
      if (value.error){
        toast.error(`${_('No pudimos finalizar la acción de contacto, razón:')} \n${value.error.message || _('Desconocido')}`);
      }else{
        toast.success(_("Datos enviados correctamente!"));
        setContacted(true);
      }
    });
  };
  return contacted?<span className="w-full text-[#F4DE88] font-avertasemi text-[1.25rem] leading-[23.44px] 2xl:leading-normal 2xl:text-[1.75rem]">
        {_("Datos enviados, le responderemos en la mayor brevedad posible")}
      </span>:(
        <>
          <div className="w-full text-[#F4DE88] font-avertasemi text-[1.25rem] leading-[23.44px] 2xl:leading-normal 2xl:text-[1.75rem]">
            <p>
              {_("Introduzca sus datos y envíe la solicitud: le responderemos lo antes posible.")}
            </p>
          </div>
          <div className="w-full">
            <Formik
              initialValues={{
                name: "",
                lastname: "",
                message: "",
              }}
              onSubmit={handleSubmit}
            >
              <Form className="mt-[1.60rem] xl:flex xl:flex-col xl:gap-[1.5rem] w-full">
                <Field
                  className="w-full font-averta placeholder-[#F4DE88] placeholder-opacity-75 bg-[#000] text-[#F4DE88] border-b border-b-[#F4DE88] py-[0.5rem] focus:outline-none xl:text-[1.375rem] "
                  id="name"
                  name="name"
                  placeholder={_("Nombre")}
                />
                
                <Field
                  className="w-full font-averta placeholder-[#F4DE88] placeholder-opacity-75 bg-[#000] text-[#F4DE88] border-b border-b-[#F4DE88] py-[0.5rem] focus:outline-none xl:text-[1.375rem] "
                  id="lastname"
                  name="lastname"
                  placeholder={_("Apellidos")}
                />

                <Field
                  className="w-full font-averta placeholder-[#F4DE88] placeholder-opacity-75 bg-[#000] text-[#F4DE88] border-b border-b-[#F4DE88] py-[0.5rem] focus:outline-none xl:text-[1.375rem] "
                  id="email"
                  name="email"
                  placeholder={_("Correo electrónico")}
                />
                <Field
                  className="w-full font-averta placeholder-[#F4DE88] placeholder-opacity-75 bg-[#000] text-[#F4DE88] border-b border-b-[#F4DE88] py-[0.5rem] focus:outline-none xl:text-[1.375rem] "
                  id="phone"
                  name="phone"
                  placeholder={_("Teléfono")}
                />

                <Field
                  className="w-full font-averta placeholder-[#F4DE88] placeholder-opacity-75 bg-[#000] text-[#F4DE88] border-b border-b-[#F4DE88] py-[0.5rem] focus:outline-none xl:text-[1.375rem] "
                  id="message"
                  name="message"
                  placeholder={_("Mensaje")}
                />

                <button
                  type="submit"
                  className="w-full mt-[2.31rem] px-5 py-2 flex justify-between items-center border-[2px] border-[#F4DE88] bg-[#000000] text-[#F4DE88] text-base md:w-fit md:gap-5 h-[3.0625rem]"
                  disabled={isLoading}
                >
                  <p className="pr-2 font-semibold xl:text-[1.5rem]">
                    {_("Enviar")}
                  </p>
                  <div className="w-max-6">
                    {
                    isLoading?(
                      <Spinner />
                      ):isError?<Erroricon />:<img src={GOLD_RIGHT_ARROW} alt="gold arrow" />
                    }
                  </div>
                </button>
              </Form>
            </Formik>
          </div>
        </>
      )
};
