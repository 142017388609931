import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import GOLD_ARROW from "../../assets/icons/arrowBannerCarousel.svg";

import "swiper/css/navigation";
import "swiper/css";

export const Carrousel = ({items})=>{
  return (
    <div className="flex h-100%" >
      <Swiper
        className="m-0 w-full" style={{maxWidth:'100vw'}}
        modules={[Pagination, Navigation]}
        loop={items.length > 1}
        navigation={{
          nextEl: ".nextEl",
          prevEl: ".prevEl",
        }}
        pagination={{
          el: ".pagination",
          clickable: true,
        }}
        slidesPerView={1}
      >
        {items.map(({ image,}, index) => (
            <SwiperSlide key={index} className="z-0">
              <img
                className="object-cover w-full h-[14rem] xl:h-screen "
                src={image}
                alt='Loading...'
              />
            </SwiperSlide>
        ))}
        <div className="absolute pagination h-[1.2rem] bottom-[1rem] z-10 left-1/2 xl:bottom-[2.56rem]"></div>

        <button className="prevEl hidden xl:w-[3.25rem] xl:flex xl:absolute xl:left-0 xl: top-1/2 z-10">
          <img src={GOLD_ARROW} alt="GOLD_RIGHT_ARROW" />
        </button>

        <button className="nextEl hidden xl:w-[3.25rem] xl:flex xl:absolute xl:right-0 xl:top-1/2 z-10">
          <img className="rotate-180" src={GOLD_ARROW} alt="GOLD_LEFT_ARROW" />
        </button>
      </Swiper>
    </div>
  )
}

export const CollectionCarousel = ({items}) => {
  return (
    <div className="flex h-100%" >
      <Swiper
        className="m-0 w-full" style={{maxWidth:'100vw'}}
        modules={[Pagination, Navigation]}
        loop={true}
        navigation={{
          nextEl: ".nextEl",
          prevEl: ".prevEl",
        }}
        pagination={{
          el: ".pagination",
          clickable: true,
        }}
        slidesPerView={1}
      >
        {items.map(({ version,}, index) => (
          <SwiperSlide key={index} className="z-0">
            <img
              className="object-cover w-full h-[14rem] xl:h-screen "
              src={version[0]?.image}
              alt='Loading...'
            />
          </SwiperSlide>
        ))}
        <div className="absolute pagination h-[1.2rem] bottom-[1rem] z-10 xl:bottom-[2.56rem] flex justify-center w-full"></div>

        <button className="prevEl hidden xl:w-[3.25rem] xl:flex xl:absolute xl:left-0 xl: top-1/2 z-10">
          <img src={GOLD_ARROW} alt="GOLD_RIGHT_ARROW" />
        </button>

        <button className="nextEl hidden xl:w-[3.25rem] xl:flex xl:absolute xl:right-0 xl:top-1/2 z-10">
          <img className="rotate-180" src={GOLD_ARROW} alt="GOLD_LEFT_ARROW" />
        </button>
      </Swiper>
    </div>
  );
};
