import  { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BaseURL } from './apiConfig'
import { any } from 'prop-types';


export const OurCompanyInfo = {
  "id": any,
  "title": String,
  "description": String,
  "image": String
}

export const aboutPageApi = createApi({
  reducerPath: 'contactPageApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL
  }),
  endpoints: (builder) => {
    return  ({
      postMessageForm: builder.mutation({
        query: (message) => ({
          url: '/products/contact',
          method: 'post',
          body: message
        }),
      }),
      getAllOurValues: builder.query({
        query: () => ({url:'/products/our-values/'})
      }),
      getOurCompanyDetails: builder.query({
        query: () => ({url:'/products/our-companies/'})
      })
  
    })
  }
})

export const { usePostMessageFormMutation, useGetAllOurValuesQuery, useGetOurCompanyDetailsQuery } = aboutPageApi