import { Title } from "../../components/Title";


export const OurValuesSection = ({title, slots}) => {

  return (
    <div className="flex flex-col px-[3.25rem] text-center justify-center xl:flex xl:flex-row xl:flex-wrap xl:text-start xl:px-0" style={{maxWidth:'100vw'}}>
      <div className="xl:w-full">
        <Title>
          <h1 className="text-[1.875rem] xl:text-[3.2rem] 2xl:text-[4.375rem]">
            {title}
          </h1>
        </Title>
      </div>
      <div className="relative flex justify-between w-full max-w-[100vw] flex-wrap mb-3" style={{overflowWrap:'everywhere', wordBreak:'break-all'}}>
        {slots.map(({ title, subtitle, content }, index) => {
          return (
            <div
              key={index}
              className="flex flex-col mt-[2.87rem] items-around justify-between text-center w-full md:max-w-[25vw]"
            >
              <p className="font-averta text-[#FFF] text-[1.125em]">
                {subtitle}
              </p>

              <Title>
                <p className="text-[3.75rem]">
                  {title}
                </p>
              </Title>

              <p className="font-averta text-[#FFF] text-[1.125rem] 2xl:text-[1.75rem]">
                {content}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
