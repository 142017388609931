import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination } from "swiper/modules";


import "swiper/css/navigation";
import "swiper/css";
import { TextLight } from "../../components/Texts";
import { Title } from "../../components/Title";
import { api } from "../../api/api";
import { Spinner } from "../../assets/icons/Spinner";
import { Erroricon } from "../../assets/icons/Erroricon";
import React from "react";
import {renderToString} from 'react-dom/server';
import { Link } from "react-router-dom";
import { _ } from "../../i18n";


export const CarouselRelatedProducts = ({category, exclude}) => {
  const {data, isLoading, isSuccess, isError} = api.useGetItemByCategoryIdQuery(category);
  // React.useEffect(()=>{
  // });
  return isLoading?<Spinner></Spinner>:isError?<Erroricon></Erroricon>:isSuccess && data.length > 1?(
    <div className="px-[13%] py-[5%]">
      <Title>
        <h1 className="text-[1.875rem] xl:text-[3.2rem] 2xl:text-[4.375rem]">
          {_('Productos Relacionados')}
        </h1>
      </Title>
      <div className="flex flex-row items-end">
        <Swiper
          className="relative w-[100%] md:w-[80%] pr-[200px] complicated-slide" style={{margin:'auto 0', display:'flex', flexDirection:'row'}}
          modules={[Navigation, Pagination]}
          slidesPerView={3}
          loop={data.length > 4}
          setWrapperSize={false}
          spaceBetween={25}
          breakpoints={{
            360: {
              slidesPerView: 1.3,
            },
            768: {
              slidesPerView: 3,
            },
          }}
          pagination={{
            el: ".pagination-related",
            clickable: true,
            type:'custom',
            renderCustom:(swiper, current, total)=>{
              var result = [];

              for(let i=0; i < total; i++){
                if(current===i+1){
                  result.push(<Title className="swiper-pagination-bullet bg-none" key={i} style={{fontSize:'1.2rem', lineHeight:'15px', cursor:'pointer'}}>{i + 1}<hr style={{width:'150%'}}></hr></Title>)
                }else{
                  result.push(<Title className="swiper-pagination-bullet bg-none" onClick={(event)=>swiper.slideTo(i+1)} key={i} style={{fontSize:'1.2rem', lineHeight:'15px', cursor:'pointer', color:'#909090'}}>{i + 1}<hr style={{width:'150%'}}></hr></Title>)
                }
              }
              return renderToString(<>{result}</>)
            }
          }}
        >
          {data.map(({ version, name, id }, index) => {
            if (id === exclude) return null;
            return <SwiperSlide key={index}>
              <Link to={`/products/details/${id}`}>
                <img
                  className="object-cover w-full h-[414.5px]"
                  src={version[0]?.image}
                  alt={name}
                />
                <TextLight>
                  {name}
                </TextLight>
              </Link>
            </SwiperSlide>
          })}
        </Swiper>
        <div className="hidden md:flex pagination-related flex-row gap-[10px] w-[20%] mb-[20px] ml-[10px] h-[20px] z-10"></div>
      </div>
    </div>
  ):null;
};
