import { Title } from "../../components/Title";
import VECTOR_ARROW from "../../assets/icons/vectorArrow.svg";
import { Link } from "react-router-dom";
import { TextLight } from "../../components/Texts";


/**
 * 
 * @param {{galleryId:number, prevFunc:()=>void, nextFunc:()=>void}} param0 
 * @returns 
 */
export const GalleryNameDesktop = ({
  name,
}) => {
  return (
    <div className="hidden xl:pb-[0.44rem] md:flex items-center justify-between">
      <div className="flex h-1/2">
        <Link className="flex items-center gap-[0.19rem]" to={"/gallery"}>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          < path d="M18.2281 8.32754H4.26562L10.3656 2.22754C10.8531 1.74004 10.8531 0.940039 10.3656 0.452539C9.87812 -0.0349609 9.09062 -0.0349609 8.60312 0.452539L0.365625 8.69004C-0.121875 9.17754 -0.121875 9.96504 0.365625 10.4525L8.60312 18.69C9.09062 19.1775 9.87812 19.1775 10.3656 18.69C10.8531 18.2025 10.8531 17.415 10.3656 16.9275L4.26562 10.8275H18.2281C18.9156 10.8275 19.4781 10.265 19.4781 9.57754C19.4781 8.89004 18.9156 8.32754 18.2281 8.32754Z" fill="#F4DE88"/>
          </svg>
          <TextLight>
            <p className="text-[1.25rem]">Volver a la Galeria</p>
          </TextLight>
        </Link>
      </div> 

      <div className="flex w-[80%] justify-around ">
        <div className="col-span-2">
          <Title>
            <h1 className="xl:text-[3.2rem] 2xl:text-[4rem]">
              {name}
            </h1>
          </Title>
        </div>
        <div className="flex items-center gap-[2.5rem]">
          <button className="prevElGallery">
            <img
              className="rotate-180 w-[0.8125rem]"
              src={VECTOR_ARROW}
              alt="VECTOR_ARROW"
            />
          </button>
          
          
          <button className="nextElGallery">
            <img
              className="w-[0.8125rem]"
              src={VECTOR_ARROW}
              alt="VECTOR_ARROW"
            />
          </button>
        </div>
      </div>
    </div>
  );
};
