import { TextLight } from "../../components/Texts";
import { Title } from "../../components/Title";

/**
 * 
 * @param {{
 *   id: any;
 *   title: any;
 *   description: any;
 *   image: any;
 *}} param0 
 * @returns 
 */
export const SectionCardLeft = ({
  title,
  image,
  description,
}) => {
  return (
    <div className="xl:pb-[11.6rem] xl:w-full xl-h-full">
      <div className="pr-[1.25rem] text-end mb-[1.5rem] xl:w-full xl:text-end xl:pl-0 ">
        <Title>
          <h1 className="text-[1.875rem] xl:text-[3rem] 2xl:text-[4.375rem]">
            {title}
          </h1>
        </Title>
      </div>

      <div className="xl:flex xl:justify-around relative" style={{alignItems:'center'}}>
        <img
            className="relative xl:min-w-[60%] xl:left-[5%] object-cover xl:w-2/3 2xl:min-w-[51.875rem] 2xl:min-h-[35.4375rem]"
            src={image}
            alt={title}
          />
        <div className="relative xl:min-w-[50%] xl:my-[5%]  xl:right-[5%] py-[1.44rem] pl-[1.62rem] pr-[0.69rem] w-[17.6875rem] border border-[#F4DE88] bg-[#000] xl:max-h-[80%] xl:h-[20rem] xl:min-w-[25rem] 2xl:min-h-[25rem] 2xl:min-w-[39rem] ">
          <TextLight>
            <p className="text-[1rem] leading-[1.6rem] font-averta xl:text-[1.3rem] xl:leading-[1.7rem] 2xl:text-[1.5rem] 2xl:leading-[2.4rem]">
              {description}
            </p>
          </TextLight>
        </div>
      </div>
    </div>
  );
};
