import { SeeMoreBtn } from "../../components/Btns/SeeMoreBtn";
import { TextLight } from "../../components/Texts";
import { Title } from "../../components/Title";


/**
 * 
 * @param {{title:String, description: String, id:Number, style: React.CSSProperties, className:String, actionLink: String, disableLink: Boolean }} param0 
 * @returns 
 */
export const InfoCardWhitBtn = ({ title, description, id, style, className, actionLink, disableLink, actionText }) => {
  return (
    <div style={style} className={`flex flex-col justify-start pt-[2rem] pb-[2.6rem] xl:pt-[1rem] 2xl:pt-[1.94rem] px-[1.25rem] 2xl:px-[2.65rem] ${className || ''}`}>
      <Title>
        <h1 className="font-unna font-bold tracking-wide">{title}</h1>
      </Title>

      <div>
        <TextLight>
          <p className=" leading-[1.426rem] xl:leading-[1.7rem] 2xl:leading-[2.139rem] font-thin xl:text-[1rem] 2xl:text-[1.5rem]">
            {description}
          </p>
        </TextLight>
      </div>
      {!disableLink?(
        <div className="w-[100%] pt-5">
          <SeeMoreBtn text={actionText} link={actionLink || `/collection/details/${id}`} />
        </div>
      ):null
      }
    </div>
  );
};
