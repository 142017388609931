import { TextLight } from "../../components/Texts";
import { Title } from "../../components/Title";
import { LinkBtn } from "../../components/Btns/LinkBtn";
import { _ } from "../../i18n";

export const BigInfoCardWhitBtn = ({id, name, category, image, description, right}) => {
  const ImageSlot = ()=>{
    return <div className="flex w-full" style={{justifyContent:'space-between', alignItems:'center'}}>
      {right?null:(
        <Title className='h-full w-[20%] relative flex flex-col justify-end' style={{overflow:'hidden', textOverflow:'clip'}}>
          <h1 style={{transformOrigin:'0 0', transform: 'rotate(-90deg)', whiteSpace:'pre'}}>
            {category?.name}
          </h1>
        </Title>
      )}
      <img
        className="object-cover w-full xl:w-[80%] md:w-[60%] xl:h-[47.5625rem] col min-w-[200px]"
        src={image}
        alt={name}
      />
    </div>
  }
  const DataSlot = ()=>{
    return <div className="px-[1.25rem] xl:max-w-[32.1875rem] w-full xl:gap-y-[2.25rem] xl:pt-20">
      <div>
        <Title>
          <h1>{name}</h1>
        </Title>
      </div>
      <div className=" xl:pt-[3rem]">
        <TextLight>
          <p className=" leading-[1.426rem] xl:text-[1.3rem] xl:leading-[2rem] 2xl:text-[1.5rem] 2xl:leading-[2.139rem]">
            {description}
          </p>
        </TextLight>
      </div>

      <div className="w-full pt-[2rem] xl:pt-[3rem]">
        <div className="w-full">
          <LinkBtn link={`/collection/details/${id}`}>
            <p>{_('Ver más')}</p>
          </LinkBtn>
        </div>
      </div>
    </div>
  }
  return (
    <div className="flex gap-y-[2rem] flex-col sm:flex-row xl:justify-center xl:py-[8.75rem] xl:px[13%] 2xl:px-[15%] gap-[4.20rem]" style={{justifyContent:'flex-end'}}>
      {right?(
      <>
        <DataSlot></DataSlot>
        <ImageSlot></ImageSlot>
      </>):(
      <>
        <ImageSlot></ImageSlot>
        <DataSlot></DataSlot>
      </>)
      }
    </div>
  );
};
