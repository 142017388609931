import { useParams } from "react-router-dom";
import { api } from "../api/api";
import { BottomCarouselProductsTwo } from "../layout/ProductsTwo/BottomCarouselProductsTwo";
import { CarouselProductsTwo } from "../layout/ProductsTwo/CarouselProductsTwo";
import { InfoCardProductsTwo } from "../layout/ProductsTwo/InfoCardsProductsTwo";
import { Spinner } from "../assets/icons/Spinner";
import { Erroricon } from "../assets/icons/Erroricon";
import { useState } from "react";
import * as React from 'react';

// import {Button} from '@mui/base/';
import Dialog from '@mui/material/legacy/Dialog/';
import DialogTitle from '@mui/material/legacy/DialogTitle/';
import DialogContent from '@mui/material/legacy/DialogContent/';
import DialogContentText from '@mui/material/legacy/DialogContentText/';
import TextField from '@mui/material/legacy/TextField/';
import DialogActions from '@mui/material/legacy/DialogActions/';
import Button from '@mui/material/legacy/Button';
/*
*/
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import {_} from '../i18n';


export const ProductsTwo = () => {
  const [postMessageForm] = api.usePostRequestMutation();
  const [values, setValues] = useState({'fullname': '', 'email':'', 'phone':'', 'address':''});
  const [choosedItem, setChoosedItem] = useState(0);
  
  const {id} = useParams();
  const [showForm, setShowForm] = useState(false);
  const {data, isLoading, isSuccess, isError, error} = api.useGetItemByIdQuery(id);
  async function submit(formValues, dismiss){

    const d = formValues || values;
    d['version'] = data.version[choosedItem].id; 
    d['product'] = id;
    await toast.promise(postMessageForm(d), _('Sus datos se han enviado correctamente')).then((value)=>{
      dismiss()
      if(value.error){
        toast.error(`${_("Un error ocurrio y no pudimos recoger su pedido, mensaje del error")}: ${value.message || _('Error desconocido')}`)
      }else{
        toast.success(_(`Datos enviados correctamente`));
      }
    })
  }


  return isLoading?<Spinner h={'screen'}></Spinner>:isError?<Erroricon  h={'screen'} content={data?.error || error.error || error.status}></Erroricon>:isSuccess?(
    <div className="bg-[#000]">
      <CarouselProductsTwo item={data} state={setChoosedItem} />
      <div className="flex flex-col md:flex-row md:justify-between md:pt-[10.5%] md:pb-[8.6rem] md:pl-[14%] md:pr-[10.5%] mt-10">
        <InfoCardProductsTwo onSubmit={(event)=>{event.preventDefault();setShowForm(true)}} link={`/request`} title={data.name} description={data.description} images={data.version.map(({image})=>image)} actionText={`Get ${data.name}`} />
        <BottomCarouselProductsTwo images={data.version.map(({image})=>image)} />
      </div>
      <Dialog open={showForm} onClose={()=>setShowForm(false)}>
        <DialogTitle>{_("Solicitar")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {_('Para solicitar este elemento por favor rellene el formulario a continuacion y presione la opcion de enviar. En breve sera atendido.')}
          </DialogContentText>
          <Formik
              initialValues={values}
              onSubmit={(values)=>submit(values, ()=>setShowForm(false))}
            >
              <Form onChange={(event)=>{
                values[event.target.name] = event.target.value;
                setValues(values);
              }} className="mt-[1.60rem] xl:flex xl:flex-col xl:gap-[1.5rem]">
                <TextField
                  autoFocus
                  margin="dense"
                  id="fullname"
                  label={_("Nombre Completo")}
                  type="text"
                  fullWidth
                  name="fullname"
                  variant="standard"
                />
                <TextField
                  margin="dense"
                  id="email"
                  label={_("Correo Electrónico")}
                  type="email"
                  fullWidth
                  name="email"
                  variant="standard"
                />
                <TextField
                  margin="dense"
                  id="phone"
                  label={_("Número de teléfono")}
                  type="phone"
                  fullWidth
                  name="phone"
                  variant="standard"
                />
                
                <TextField
                  margin="dense"
                  id="address"
                  label={_("Dirección")}
                  type="address"
                  fullWidth
                  name="address"
                  variant="standard"
                />

                <button
                  type="submit"
                  className="hidden"
                  disabled={isLoading}
                >
                </button>
              </Form>
            </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setShowForm(false)}>{_("Cancelar")}</Button>
          <Button onClick={()=>submit(null, ()=>setShowForm(false))}>{_("Enviar")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  ):null;
};
