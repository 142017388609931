import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { BaseURL } from "./apiConfig"
import { object } from "prop-types"

export const NewsInfo = {
  id: Number,
  image: String,
  name: String,
  description: String
}
export const NewsResponse = {
  total: Number,
  totalNotFiltered: Number,
  rows: object
}

export const newsApi = createApi({
  reducerPath: 'newsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL
  }),
  endpoints: (builder) => ({
    getNews: builder.query({
      query: () => ({url:"/products/news-empresas/"})
    })
  })
})

export const { useGetNewsQuery } = newsApi