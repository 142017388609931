import { NewsInfo } from "../../api/newsApi"; // eslint-disable-line no-unused-vars
import { LinkBtn } from "../../components/Btns/LinkBtn";
import { TextLight } from "../../components/Texts";
import { Title } from "../../components/Title";
import { _ } from "../../i18n";


/**
 * 
 * @param {{newToRender: NewsInfo}} param0 
 * @returns 
 */
export const GridStyleOne = ({ newToRender }) => {
  const { id, name, version, description } = newToRender;

  return (
    <>
      <div className="overflow-hidden">
        <img className="w-full h-full object-cover" src={version[0]?.image} alt={name} />
      </div>

      <div className="flex flex-col justify-start md:px-[1rem]">
        <Title>
          <h1>{name}</h1>
        </Title>
        <div>
          <TextLight>
            <p className=" md:leading-[1.7rem] ">
              {description}
            </p>
          </TextLight>
        </div>

        <div className="w-[100%] pt-5">
          <LinkBtn link={`/products/details/${id}`}>
            <p>{_('Ver más')}</p>
          </LinkBtn>
        </div>
      </div>
    </>
  );
};


/**
 * 
 * @param {{newToRender: NewsInfo}} param0 
 * @returns 
 */
export const GridStyleTwo = ({ newToRender }) => {
  const { id, name, version, description, category } = newToRender;
  return (
    <>
      <div className="col-span-2 max-h-[30rem] overflow-hidden">
        <img className="w-full h-full object-cover" src={version[0]?.image} alt={name} />
      </div>

      <div className="flex flex-col justify-start md:px-[1rem]">
        <Title>
          <h1>{name}</h1>
        </Title>
        <div>
          <TextLight>
            <p className="md:leading-[1.7rem] ">
              {description}
            </p>
          </TextLight>
        </div>

        <div className="w-[100%] pt-5">
          <LinkBtn link={`/products/details/${id}`}>
            <p>{_('Ver más')}</p>
          </LinkBtn>
        </div>
      </div>

      <div className="overflow-hidden">
        <img className="w-full object-cover" src={category.image} alt={name} />
      </div>
    </>
  );
};

/**
 * 
 * @param {{newToRender: NewsInfo}} param0 
 * @returns 
 */
export const GridStyleThree = ({ newToRender }) => {
  const { id, name, version, description } = newToRender;
  return (
    <>
      <div className="flex flex-col justify-start md:px-[1rem]">
        <Title>
          <h1>{name}</h1>
        </Title>
        <div>
          <TextLight>
            <p className="md:leading-[1.7rem] ">
              {description}
            </p>
          </TextLight>
        </div>

        <div className="w-[100%] pt-5">
          <LinkBtn link={`/products/details/${id}`}>
            <p>{_('Ver más')}</p>
          </LinkBtn>
        </div>
      </div>

      <div className="overflow-hidden">
        <img className="w-full h-full object-cover" src={version[0]?.image} alt={name} />
      </div>
    </>
  );
};

/**
 * 
 * @param {{newToRender: NewsInfo}} param0 
 * @returns 
 */
export const GridStyleFour = ({ newToRender }) => {
  const { id, name, version, items_color, description } = newToRender;
  return (
    <>
      <div className="overflow-hidden">
        {version?(
          <div className="col-span-2 row-span-2 overflow-hidden">
            <img className="w-full h-full object-cover " src={version[0]?.image} alt={name} />
          </div>
        ):null}
        {
          items_color?(
          <div key={crypto.randomUUID()} className="overflow-hidden">
            <img className="w-full h-full object-cover" src={items_color[0]?.image_version} alt={name} />
          </div>
          ):null
        }
      </div>

      <div className="flex flex-col justify-start  md:px-[1rem]">
        <Title>
          <h1>{name}</h1>
        </Title>
        <div>
          <TextLight>
            <p className="md:leading-[1.7rem]">
              {description}
            </p>
          </TextLight>
        </div>

        <div className="w-[100%] pt-5">
          <LinkBtn link={`/products/details/${id}`}>
            {_('Ver más')}
          </LinkBtn>
        </div>
      </div>
    </>
  );
};


/**
 * 
 * @param {{newsToRender: NewsInfo[]}} param0 
 * @returns 
 */
export const NewsGridList = ({ newsToRender }) => {
  return (
    <>
      {newsToRender.map((newToRender, index) => {
        if ((index + 1) % 5 === 0 && index !== 0) {
          return (
            <GridStyleFour
              key={crypto.randomUUID()}
              newToRender={newToRender}
            />
          );
        } else if ((index + 1) % 4 === 0) {
          return (
            <GridStyleThree
              key={crypto.randomUUID()}
              newToRender={newToRender}
            />
          );
        } else if ((index + 1) % 3 === 0) {
          return (
            <GridStyleTwo key={crypto.randomUUID()} newToRender={newToRender} />
          );
        } else if ((index + 1) % 2 === 0) {
          return (
            <GridStyleOne key={crypto.randomUUID()} newToRender={newToRender} />
          );
        } else {
          return (
            <GridStyleOne key={crypto.randomUUID()} newToRender={newToRender} />
          );
        }
      })}
    </>
  );
};
