import  { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BaseURL } from './apiConfig';


export const textsApi = createApi({
  reducerPath: 'textsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL
  }),
  endpoints: (builder) => {
    return ({
      getAllPageText: builder.query({
        query: () => ({url:"/products/text-pages/"})
      }),
      getHomePageText: builder.query({
        query: () => ({url:"/products/home-page-text/"})
      }),
      getNavText: builder.query({
        query: () => ({url: '/products/text-navbars/'})
      }),
      getAllTextBtns: builder.query({
        query: () => ({url:'/products/text-btns/'})
      })
    })
  }
})

export const { useGetAllPageTextQuery, useGetNavTextQuery, useGetAllTextBtnsQuery, useGetHomePageTextQuery  } = textsApi