import { api } from "../api/api";
import { Erroricon } from "../assets/icons/Erroricon";
import { Spinner } from "../assets/icons/Spinner";
import { MapRoute } from "../components/MapRoute";
import { TextLight } from "../components/Texts";
import { Title } from "../components/Title";
import { NewsPageMainDesktop } from "../layout/NewsPage/NewsPageMainDesktop";
import { NewsPageMainMobile } from "../layout/NewsPage/NewsPageMainMobile";

export const NewsPage = () => {
  
  const { data, isLoading, isError, error } = api.useGetNewsQuery();

  return (
    <div className="bg-[#000] min-h-[100vh] pt-[2%]">
      {isLoading?<Spinner h={'screen'}></Spinner>:isError?<Erroricon h={'screen'} content={data?.error || error.error || error.status} ></Erroricon>:(
        <>
          <div className="hidden md:block px-[15%]">
            <MapRoute />
          </div>
          <div className="px-[1.25rem] py-[1.88rem] pt-[6.12rem] xl:pt-0 xl:px-[22%] text-center">
              <div>
                <Title>
                  <h1 className="text-[1.875rem] leading-normal xl:text-[3rem] 2xl:text-[4.0625rem]">
                    {data.title}
                  </h1>
                </Title>
              </div>
              <div className="pt-[1rem]">
                <TextLight>
                  <p className="text-base xl:text-[1.3rem] xl:leading-[2rem] 2xl:text-[1.75rem] 2xl:leading-[2.4955rem]">
                    {data.description}
                  </p>
                </TextLight>
              </div>
          </div>
          <NewsPageMainMobile news={data.news}/>
          <NewsPageMainDesktop news={data.news} />
        </>
      )}
    </div>
  );
};
