function SizeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <path
        fill="#F4DE88"
        fillRule="evenodd"
        d="M5.2 4.748a.433.433 0 01-.699.341L1.74 2.942a.433.433 0 010-.683L4.5.109A.433.433 0 015.2.455v1.283h10.4V.451a.434.434 0 01.7-.341l2.76 2.147a.433.433 0 010 .683L16.3 5.09a.433.433 0 01-.7-.344V3.467H5.2v1.281zm11.266 3.919H4.333a.867.867 0 00-.867.867v12.133a.866.866 0 00.867.867h12.133a.866.866 0 00.867-.867V9.534a.867.867 0 00-.867-.867zM4.333 6.934a2.6 2.6 0 00-2.6 2.6v12.133a2.6 2.6 0 002.6 2.6h12.133a2.6 2.6 0 002.6-2.6V9.534a2.6 2.6 0 00-2.6-2.6H4.333zM25.547 10.4h-1.28v10.4h1.28a.434.434 0 01.342.699L23.74 24.26a.434.434 0 01-.683 0l-2.15-2.761a.434.434 0 01.344-.699h1.28V10.4h-1.28a.434.434 0 01-.342-.698l2.148-2.761a.433.433 0 01.683 0l2.15 2.76a.433.433 0 01-.344.7z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default SizeIcon;
