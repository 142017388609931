import  { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BaseURL } from './apiConfig'
import { object } from 'prop-types'

export const ProductInfo = {
  id: Number,
  name: String,
  description: String
}
export const ProductResponse = {
  total: Number,
  totalNotFiltered: Number,
  rows: object
}

export const ProductImageInfo = {
  "id": Number,
  "image": String,
  "producto": Number,
}

export const ProductImageResponse = {
  total: Number,
  totalNotFiltered: Number,
  rows: object
}

export const productsApi = createApi({
  reducerPath: 'productsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL
  }),
  endpoints: (builder) => ({
    getProductById: builder.query({
      query: ( id ) => ({url:`/products/products/${id}/`})
    }),
    getAllProducts: builder.query({
      query: () => ({url:'/products/products/'})
    }),
    getAllProductsImages: builder.query({
      query: () => ({url:'/products/product-image/'})
    })
  })
})

export const { useGetAllProductsQuery, useGetProductByIdQuery, useGetAllProductsImagesQuery } = productsApi