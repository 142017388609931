import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import GOLD_ARROW from "../../assets/icons/arrowBannerCarousel.svg";

import "swiper/css/navigation";
import "swiper/css";


export const BottomCarouselProductsTwo = ({images}) => {
  return (
    <div className="flex flex-col md:hidden w-full h-100%">
      <Swiper
        className="m-0 w-full"
        modules={[Pagination, Navigation]}
        navigation={{
          nextEl: ".nextEl",
          prevEl: ".prevEl",
        }}
        pagination={{
          el: ".pagination",
          clickable: true,
        }}
        slidesPerView={1}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index} className="z-0">
            <img
              className="object-cover w-full h-[14rem] md:h-screen "
              src={image}
              alt='Cargando'
            />
          </SwiperSlide>
        ))}
        <div className="absolute pagination h-[1.2rem] bottom-[1rem] z-10 left-1/2 md:bottom-[2.56rem]"></div>

        <button className="prevEl hidden md:w-[3.25rem] md:flex md:absolute md:left-0 md: top-1/2 z-10">
          <img src={GOLD_ARROW} alt="GOLD_RIGHT_ARROW" />
        </button>

        <button className="nextEl hidden md:w-[3.25rem] md:flex md:absolute md:right-0 md:top-1/2 z-10">
          <img className="rotate-180" src={GOLD_ARROW} alt="GOLD_LEFT_ARROW" />
        </button>
      </Swiper>
    </div>
  );
};
