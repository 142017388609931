import { TextLight } from "../../components/Texts";

export const Erroricon = ({ w = "6", h = "6", content='Error desconocido' }) => {
  return (<div className="bg-transparent p-5 w-full h-full flex justify-center gap-1 items-center" role="status">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="#A92407"
        className={`w-${w} h-${h}`}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
        />
      </svg>
      <TextLight>{content}</TextLight>
  </div>
  );
};
