import { Link } from "react-router-dom";
import { ReactElement } from "react"; // eslint-disable-line no-unused-vars
import { ArrowRight } from "../../assets/icons/ArrowRight";


/**
 * 
 * @param {{children: ReactElement, link: string}} param0 
 * @returns 
 */
export const LinkBtn = ({ children, link }) => {
  return (
    <Link
      to={link}
      className={`w-full m-0 relative px-3 py-1 flex justify-between items-center border-[2px] h-[2.875rem] border-[#F4DE88] bg-[#000000] text-[#F4DE88] transition duration-300 text-base md:gap-2 md:h-[3.2rem] hover:bg-[#F4DE88] hover:text-[#000]`}
    >
      <div className="font-semibold md:text-[1.3rem] text-center w-full">
        {children}
      </div>
      <div className="fill-[#F4DE88]">
        <ArrowRight />
      </div>
    </Link>
  );
};
