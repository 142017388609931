import { _ } from "../../i18n";
import { NewsGridList } from "./NewsGridList";
import { Link } from "react-router-dom";

export const NewsPageMainDesktop = ({news}) => {

  return (
    <div className="hidden md:flex md:flex-col md:items-center md:justify-center md:gap-[8.13rem] md:pb-[9rem]">
      <div className="md:grid md:grid-cols-4 md:pt-[5rem] md:gap-2">
        <NewsGridList newsToRender={news} />
      </div>
      <div className="flex justify-center w-full">
        <Link
          to={'/products/'}
          className="text-[#F4DE88] border-[#F4DE88] border-[2px] text-[1.625rem] font-avertasemi h-[4.375rem] w-[25.375rem] text-center p-3"
        >
          {_("Ver más")}
        </Link>
      </div>
    </div>
  );
};
