import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css";
import GOLD_ARROW from "../../assets/icons/arrowBannerCarousel.svg";



/**
 * 
 * @param {{images:[{image:'urltomyimage'}]}} param0 
 * @returns 
 */
export const CarouselGalleryDetails = ({ images }) => {
  
  return (
    <div className="relative">
      <div className="flex flex-col">
        <Swiper
          className="m-0 max-w-[100%]"
          modules={[Pagination, Navigation]}
          navigation={{
            nextEl: ".nextEl",
            prevEl: ".prevEl",
          }}
          autoplay={true}
          pagination={{
            el: ".dots",
            clickable: true,
          }}
          slidesPerView={1}
        >
            {
              images?.map(({image}, index) => (
                <SwiperSlide key={index} className="z-0 w-full">
                  <img crossOrigin="anonymous" className="object-contain md:max-h-[100vh] max-h-[40vh] h-[53.1875rem] w-full"
                    src={image}
                    alt='Cargando...'
                    />
                </SwiperSlide>
              ))
            }
        </Swiper>

        
        <button className="nextEl absolute top-1/2 right-0 z-10 w-[2.4375rem] md:w-[3.25rem] hidden md:block">
          <img className="rotate-180" src={GOLD_ARROW} alt="GOLD_LEFT_ARROW" />
        </button>

        <button className="prevEl absolute top-1/2 left-0 z-10 w-[2.4375rem] md:w-[3.25rem] hidden md:block">
          <img src={GOLD_ARROW} alt="GOLD_RIGHT_ARROW" />
        </button>
        
        <div className="md:hidden w-full flex justify-center">
          <div className=" flex dots mt-[0.69rem]"></div>
        </div>
      </div>
    </div>
  )
};
