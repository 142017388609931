import { useState } from "react";
import ColorPalette from "../../assets/icons/ColorPalette";
import ColorPaletteBlack from "../../assets/icons/ColorPaletteBlack";
import VectorArrow from "../../assets/icons/VectorArrow";
import { Title } from "../../components/Title";
import { Controller, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export const DetailsColor = ({colors, controller}) => {
  const [isOpen, setisOpen] = useState(false);

  return colors.length>0?(
    <>
      <div className={`absolute bottom-0 left-0 z-10  max-w-[30vw] xl:hidden transition-all w-[5rem] flex flex-col justify-between items-center`}>
        {isOpen?(
          <>
            <div onClick={() => setisOpen(!isOpen)} className={`bg-[#F4DE88] w-full flex justify-center items-center rounded-se-[2rem] p-3 cursor-pointer`}>
              <ColorPaletteBlack />
            </div>
            
            <div className="p-4 bg-black w-full h-[11.3125rem] text-center">
              <Swiper slidesPerGroup={5}
                slidesPerView={5}
                className="w-full h-full"
                direction="vertical"
                modules={[Pagination, Navigation, Controller]}
                controller={{control:controller}}
                navigation={{
                  nextEl: ".nextEl",
                  prevEl: ".prevEl",
                }}
              >
                {colors.map(({rgb}, index)=>{
                  return <SwiperSlide key={index} className="z-0 flex justify-center items-center h-auto cursor-pointer p-0 m-0">
                        <svg onClick={()=>{controller.slideTo(index)}} className="h-full w-full" viewBox="0 0 45 45" fill={rgb} xmlns="http://www.w3.org/2000/svg">
                        <circle cx="22.5" cy="22.5" r="20.5" fill={rgb} stroke="#F4DE88" strokeWidth="4"/>
                      </svg>
                    </SwiperSlide> 
                })}
              </Swiper>
            </div>
          </>
        ):(
          <div onClick={() => setisOpen(!isOpen)} className="bg-black w-full flex flex-col justify-center items-center rounded-se-[2rem] pr-[0.88rem] pl-[0.63rem] pt-[0.94rem] pb-[0.5rem] cursor-pointer">
            <div className={`rotate-[270deg]`}>
              <VectorArrow />
            </div>

            <div className={`${isOpen && `hidden`}`}>
              <ColorPalette />
            </div>
          </div>
        )}
      </div>
      <div className="absolute bottom-0 left-0 z-10 max-w-[30vw] hidden xl:flex items-center bg-black xl:h-[4rem] xl:w-[100%] rounded-se-[4rem] px-5 gap-[5px]">
        <Title style={{fontSize:'xx-large'}}>Color: </Title>
        <Swiper slidesPerGroup={5}
          slidesPerView={5}
          className="w-full"
          modules={[Pagination, Navigation, Controller]}
          controller={{control:controller}}
          navigation={{
            nextEl: ".nextEl",
            prevEl: ".prevEl",
          }}
        >
          {colors.map(({rgb}, index)=>{
            return <SwiperSlide key={index} className="z-0">
                  <svg onClick={()=>{controller.slideTo(index)}} width="35" height="35" viewBox="0 0 45 45" fill={rgb} xmlns="http://www.w3.org/2000/svg">
                    <circle cx="22.5" cy="22.5" r="20.5" fill={rgb} stroke="#F4DE88" strokeWidth="4"/>
                </svg>
              </SwiperSlide> 
          })}
        </Swiper>
      </div>
    </>
  ):null;
};
