import { GetTheProductBtn } from "../../components/Btns/PickProductsBtn";
import { TextLight } from "../../components/Texts";
import { Title } from "../../components/Title";
import { _ } from "../../i18n";


/**
 * 
 * @param {{id: number, name: string, images:string, description: string}} param0 
 * @returns 
 */
export const InfoCardWithTwoPhotos = ({id, name, images, description, link}) => {

  return (
    <div className="flex flex-col md:flex-row w-full gap-5">
      <div className="flex flex-col items-center md:w-[60%] md:gap-[1.06rem] md:order-2 ">
        <div className="flex justify-end w-full md:w-[60%]">
          <img className="w-full md:w-[90%] h-full" src={images[0]} alt={name} />
        </div>
        {
        images.length > 1?<div className="md:translate-x-[-1rem] w-full md:w-[60%]">
            <img className="w-full md:w-[90%] h-full" src={images[1]} alt={name} />
          </div>:null
        }
      </div>
      <div className="md:order-1 md:pt-[2rem] pb-[2.44rem]">
        <div className="px-[1.25rem] md:max-w-[32.1875rem] md:gap-y-[2.25rem] md:pt-0">
          <div>
            <Title>
              <h1>{name}</h1>
            </Title>
          </div>
          <div>
            <TextLight>
              <p className=" text-base md:pt-[1rem] md:text-[1.3rem] md:-leading-[2rem]">
                {description}
              </p>
            </TextLight>
          </div>

          <div className="w-full pt-[1.5rem] md:pt-[2.5rem] ">
            <div className="w-3/4 md:w-auto">
              <GetTheProductBtn text={`${_('Obtener')} ${name}`} link={link} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
