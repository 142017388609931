import  { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BaseURL } from './apiConfig'
import { any, object } from 'prop-types'

export const CategoriesInfo = {
  "id": Number,
  "name": String,
  "banner": String,
  "description": String,
}
export const CategoriesResponse = {
  "total": Number,
  "totalNotFiltered": Number,
  "rows": object
}
export const SubcategoryInfo = {
  "id": Number,
  "name": String,
  "banner": null,
  "image": String,
  "product": any
}
export const SubcategoriesResponse = {
  "total": Number,
  "totalNotFiltered": Number,
  "rows": any
}
export const CategoriesImageResponse = {
  "total": Number,
  "totalNotFiltered": Number,
  "rows": object
}
export const CategoriesImageInfo = {
  "id": Number,
  "image":String,
  "categoria":Number
}


export const categoriesApi = createApi({
  reducerPath: 'categoriesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL
  }),
  endpoints: (builder) => ({
    getAllCategories: builder.query({
      query: () => ({url:'/products/category-products/'})
    }),
    getAllSubcategories: builder.query({ 
      query: () => ({url:'/products/subcategory-products/'})
    }),
    getSubcategoriesForCategory: builder.query({ // FIXME 
      query: (category_id) => ({url:'/products/category/'})
    }),
    getAllCategoriesImage: builder.query({
      query: () => ({url:'/products/category-image/'})
    })
  })
})


export const { useGetAllCategoriesQuery, useGetAllSubcategoriesQuery, useGetAllCategoriesImageQuery, useGetSubcategoriesForCategoryQuery } = categoriesApi