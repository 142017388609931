import { api } from "../api/api";
import { Erroricon } from "../assets/icons/Erroricon";
import { Spinner } from "../assets/icons/Spinner";
import { LinkCard } from "../components/LinkCard";
import { MapRoute } from "../components/MapRoute";

export const GalleryPage = () => {
  const { data, isLoading, isFetching, isError, error, isSuccess } = api.useGetGalleryQuery();

  return (
    <div className="bg-[#000] px-[1.25rem] min-h-[100vh] pt-[2%] md:px-[15%] ">
      {isError?<Erroricon h={'screen'} content={data?.error || error.error || error.status} />:(isLoading||isFetching)?<div className="flex  items-center justify-center">
            <Spinner h={'screen'} />
          </div>:isSuccess?(
            <>
              <MapRoute />
              <div className="grid grid-cols-1 gap-[1.94rem] md:grid-cols-3 md:gap-x-[1.44rem] md:gap-y-[3.25rem] ">
                {
                  data?.map(({id, category, name}, index) => {
                    return (
                      <LinkCard
                        key={index}
                        id={id}
                        image={category?.image}
                        name={name}
                      />
                    );
                  })
                }
              </div>
            </>
      ):null}
    </div>
  );
};
