import { ArrowRight } from "../assets/icons/ArrowRight";
import { _ } from "../i18n";


/**
 * 
 * @param {{
 * title:'Title of the button',
 * sectionLink:'The link to the section which this button point to i.e: https://sunosh.com/section or /section'
 * }} param0 
 * @returns 
 */
export const BannerBtn = ({title, sectionLink, icon}) => {
  return (
    <button style={{backgroundColor:'rgba(0, 0, 0, 0.25)'}} onClick={()=>{
        if(sectionLink)window.location.href=sectionLink
      }} className={`text-center justify-between items-center w-[17.93] py-[0.14rem] flex gap-[1rem] text-[#F4DE88] z-10 xl:w-[27rem] xl:gap-[1.75rem]${!sectionLink?'cursor-default':null}`}>
      {
        icon?(
          <div className="p-[4.88px] flex h-full bg-[#000]">
            <svg className="aspect-square justify-center align-center" height="100%" >
              <image xlinkHref={icon} src={icon} width={'100%'} height={'100%'}/> 
            </svg>
          </div>
        ):null
      }

      {
        title?(
          <div className={`font-unna font-bold w-full ${!icon?'pl-5':null} ${!sectionLink?'pr-5':null} ${!icon&&!sectionLink?'text-[3rem]':'text-[1rem]'}`}>
            {title}
          </div>
        ):null
      }
      {
        sectionLink?(
          <div className="flex items-center w-full">
            <p className="font-avertablack text-[0.875rem] xl:text-[1.3rem] 2xl:text-[1.5rem]" style={{fontWeight: 400}}>
              {_('Ver más')}
            </p>
            <div className="w-[1.375rem] mx-3 xl:w-[1.9375rem] ">
              <ArrowRight />
            </div>
          </div>
        ):null
      }
    </button>
  );
};
