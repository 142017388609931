import { Link } from "react-router-dom";

import WHATSAPP_ICON from "../assets/icons/whatsappIcon.svg";
import FACEBOOK_ICON from "../assets/icons/facebookIcon.svg";
import INSTAGRAM_ICON from "../assets/icons/instagramIcon.svg";
import { api } from "../api/api";
import { Spinner } from "../assets/icons/Spinner";


export const FooterSocialMedia = () => {
  const {data, isLoading, isSuccess} = api.useGetContactQuery();


  return isLoading?<Spinner></Spinner>:isSuccess?(
    <ul className="flex">
      {data.whatsapp?(
        <li
            className=" relative pr-[1.31rem] xl:pr-[1.56rem] last:pr-0 group"
          >
          <p className="hidden xl:text-[#F4DE88] transition duration-300 font-averta xl:text-[1.375rem] xl:block xl:absolute xl:bottom-[2.5rem] opacity-0 group-hover:opacity-100 left-[-50%]">
            WhatsApp
          </p>
          <Link to={data.whatsapp}>
            <img className="xl:w-[1.5rem] 2xl:w-[2rem]" src={WHATSAPP_ICON} alt='Cargando...' />
          </Link>
        </li>
      ):null}
      {
        data.facebook?(
        <li
            className=" relative pr-[1.31rem] xl:pr-[1.56rem] last:pr-0 group"
          >
          <p className="hidden xl:text-[#F4DE88] transition duration-300 font-averta xl:text-[1.375rem] xl:block xl:absolute xl:bottom-[2.5rem] opacity-0 group-hover:opacity-100 left-[-50%]">
            Facebook
          </p>
          <Link to={data.facebook}>
            <img className="xl:w-[1.5rem] 2xl:w-[2rem]" src={FACEBOOK_ICON} alt='Cargando...' />
          </Link>
        </li>
        ):null
      }
      {
        data.instagram?(
          <li
              className=" relative pr-[1.31rem] xl:pr-[1.56rem] last:pr-0 group"
            >
            <p className="hidden xl:text-[#F4DE88] transition duration-300 font-averta xl:text-[1.375rem] xl:block xl:absolute xl:bottom-[2.5rem] opacity-0 group-hover:opacity-100 left-[-50%]">
              Instagram
            </p>
            <Link to={data.instagram}>
              <img className="xl:w-[1.5rem] 2xl:w-[2rem]" src={INSTAGRAM_ICON} alt='Cargando...' />
            </Link>
          </li>
        ):null
      }
    </ul>
  ):null;
};
