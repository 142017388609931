import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Controller } from "swiper/modules";
import GOLD_ARROW from "../../assets/icons/arrowBannerCarousel.svg";

import "swiper/css/navigation";
import "swiper/css";
import { DetailsColor } from "./DetailsColor";
import { DetailsSize } from "./DetailsSize";
import { useState } from "react";


export const CarouselProductsTwo = ({item, state}) => {
  const [controller, setController] = useState();

  return item.version.length>0?(
    <div className="relative flex flex-col w-full h-screen">
      <Swiper
        className="m-0 w-full"
        modules={[Pagination, Navigation, Controller]}
        controller={{control:controller}}
        onSwiper={setController}
        navigation={{
          nextEl: ".nextEl",
          prevEl: ".prevEl",
        }}
        onSlideChange={(swiper)=>state(swiper.activeIndex)}
        pagination={{
          el: ".pagination",
          clickable: true,
        }}
        slidesPerView={1}
      >
        {item.version.map(({ image }, index) => (
          <SwiperSlide key={index} className="object-cover z-0">
            <img className="object-cover w-full h-screen" src={image} alt='Cargando..' />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="pagination z-10 bg-red absolute left-1/2 bottom-[5%]"></div>
      <button className="nextEl absolute hidden md:block top-1/2 right-0 z-10 w-[2.4375rem] md:w-[3.25rem] md:right-0">
        <img className="rotate-180" src={GOLD_ARROW} alt="GOLD_LEFT_ARROW" />
      </button>

      <button className="prevEl absolute hidden md:block top-1/2 left-0 z-10 w-[2.4375rem] md:w-[3.25rem] md:left-0">
        <img src={GOLD_ARROW} alt="GOLD_RIGHT_ARROW" />
      </button>
      <DetailsColor colors={item.version.map(({color})=>color).filter((color)=>color)} controller={controller} onColorSelect={(index)=>{controller.slideTo(index)}} />
      
      <DetailsSize sizes={item.version.map(({size})=>size).filter((size)=>size)} unit={item.size_unit} controller={controller}/>
      
    </div>
  ):<div>No hay datos que mostrar aun</div>;
};
