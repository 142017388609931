import { TextLight } from "../../components/Texts";
import { Title } from "../../components/Title";
import { GetTheProductBtn } from "../../components/Btns/PickProductsBtn";

export const InfoCardProductsTwo = ({description, title, link, images, actionText, onSubmit}) => {
  return (
    <div className="flex justify-center gap-3 items-center w-full">
      <div className="md:flex md:flex-col hidden md:gap-[1.06rem] md:order-2 md:w-[50%] xl:w-[80%] justify-between">
        <div className="flex justify-end overflow-hidden md:w-[40%] min-w-[400px]">
          <img className="object-cover aspect-square" src={images[0]} alt="FOTOPRODUCTO" />
        </div>
        <div className="md:translate-x-[-1rem] overflow-hidden md:w-[40%] min-w-[400px]">
          <img  src={images[1]} alt="FOTOPRODUCTO" />
        </div>
      </div>
      <div className="md:order-1 pb-[2.44rem] md:w-[50%]">
        <div className="px-[1.25rem] md:max-w-[32.1875rem] md:gap-y-[2.25rem] md:pt-[5rem]">
          <div>
            <Title>
              <h1>{title}</h1>
            </Title>
          </div>
          <div>
            <TextLight>
              <p className="w-min-content md:pt-[1rem] md:text-[1.3rem] leading-[2.139rem]">
                {description}
              </p>
            </TextLight>
          </div>

          <div className="w-full pt-[2.5rem]">
              <GetTheProductBtn onClick={onSubmit} text={actionText} link={link} />
          </div>
        </div>
      </div>
    </div>
  );
};
