import  { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

var T = 'https://api.sunosh.cloudfood.uk/api/';

if (window.location.host === 'localhost:3000' || window.location.host === '127.0.0.1:3000'){
    T = 'http://localhost:8000/api/';
}else if(window.location.host === 'www.sunoshcabinetry.com' || 
    window.location.host === 'sunoshcabinetry.com'){
    T = 'https://sunoshcabinetry.com/api/';
}

export const BaseURL = T;


var C = 'https://api.sunosh.cloudfood.uk/';

if (window.location.host === 'localhost:3000' || window.location.host === '127.0.0.1:3000'){
    C = 'http://localhost:8000/';
}else if(window.location.host === 'www.sunoshcabinetry.com' || 
    window.location.host === 'sunoshcabinetry.com'){
    C = 'https://sunoshcabinetry.com/';
}

export const CDN_URL = C;

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL
  }),
  endpoints: (builder) => {
    return  ({
        getAboutUs: builder.query({
            query: () => ({url:'about-us'})
        }),
        getCategory: builder.query({
            query: ()=> ({url: 'category'})
        }),
        getCollection: builder.query({
            query: () => ({url:'collection'})
        }),
        getCollectionById: builder.query({
            query: (id) => ({url:`collection/${id}`})
        }),
        getCollectionPage: builder.query({
            query: () => ({url:'collection-page'})
        }),
        postContact: builder.mutation({
            query: (message) => ({
            url: 'contact/',
            method: 'post',
            body: message
            }),
        }),
        getContact: builder.query({
            query:()=>({url:'contact'}),
        }),
        getGallery: builder.query({
            query: () => ({url:'gallery'})
        }),
        getGeneral: builder.query({
            query: () => ({url:'general'})
        }),
        getHome: builder.query({
            query: () => ({url:'home'})
        }),
        getItems: builder.query({
           query: () => ({url:'items'})
        }),
        getItemsByColor: builder.query({
           query: () => ({url:'items/list_by_color/:color/'})
        }),
        getItemsBySize: builder.query({
           query: () => ({url:'items/list_by_size/:size/'})
        }),
        getItemById: builder.query({
           query: (id) => ({url:`items/${id}/`})
        }),
        getItemByCategoryId: builder.query({
           query: (id) => ({url:`items/list_by_category/${id}/`})
        }),
        getItemByIdAndColor: builder.query({
           query: () => ({url:'items/:id/retreive_by_color/:color/'})
        }),
        getItemByIdAndSize: builder.query({
           query: () => ({url:'items/:id/retreive_by_size/:size/'})
        }),
        getNews: builder.query({
            query: () => ({url:'news'})
        }),
        postRequest: builder.mutation({
            query: (message) => ({
                url: 'request/',
                method: 'post',
                body: message
            })
        }),
        getShowRoom: builder.query({
            query: ()=> ({url: 'showroom-page'})
        }),
        getSubcategory: builder.query({
            query: ()=> ({url: 'subcategory'})
        })
    })
  }, 
})

// export const {  } = api