import { configureStore } from "@reduxjs/toolkit";
import burgerMenuReducer from './features/burgerMenuSlice'
import { aboutPageApi } from '../api/aboutAPI';
import { textsApi } from "../api/textsApi";
import { newsApi } from "../api/newsApi";
import { productsApi } from "../api/productsApi";
import { categoriesApi } from "../api/categoriesApi";
import { galleriesApi } from "../api/galleriesApi";
import { generalAPI } from "../api/generalAPI";
import { api } from "../api/api";

export const store = configureStore({
  reducer: {
    burgerMenuReducer: burgerMenuReducer,
    [generalAPI.reducerPath]: generalAPI.reducer,
    [aboutPageApi.reducerPath]: aboutPageApi.reducer,
    [textsApi.reducerPath]: textsApi.reducer,
    [newsApi.reducerPath]: newsApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [galleriesApi.reducerPath]: galleriesApi.reducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat([aboutPageApi.middleware]).concat([textsApi.middleware]).concat([newsApi.middleware]).concat(
      [productsApi.middleware]).concat([categoriesApi.middleware]).concat([galleriesApi.middleware]).concat([generalAPI.middleware]).concat(
        [api.middleware]
      )
    }
})

export const RootState = typeof store.getState;
export const AppDispatch = typeof store.dispatch;