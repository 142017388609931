import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Nuestra compañía": "Our company",
      "Contacto": "Contact",
      "Nuevos": "News",
      "Galería": "Gallery",
      "Colección": "Collection",
      "Sala de Exhibición": "Showroom",
      "Productos": "Products",
      "Contáctanos": "Contact Us",
      "Teléfono": 'Phone',
      'Correo electrónico': 'Email',
      "Ver más": "See more",
      "Todos los derechos reservados, desarrollado por": 'All rights reserved, developed by',
      "Contáctanos en": 'Contact us in',
      "Datos enviados esperando por resultados!": 'Data sended waiting for results!',
      "No pudimos finalizar la acción de contacto, razón:":'We couldnt complete the contact action, reason:',
      "Datos enviados correctamente!": 'Data correctly send!',
      "Datos enviados, le responderemos en la mayor brevedad posible": "Data correctly send, we will be in contact with you as soon as possible",
      "Introduzca sus datos y envíe la solicitud: le responderemos lo antes posible.": "Let us help you",
      "Nombre":"Name",
      "Apellidos": "Last Name",
      "Mensaje": "Message",
      "Enviar": "Send",
      "Inicio": 'Home',
      "Obtener": "Get",
      "Productos Relacionados": "Related Products",
      "Sus datos se han enviado correctamente": "Your data has been send correctly",
      "Un error ocurrio y no pudimos recoger su pedido, mensaje del error": "An error happened and we couldnt receive your request, the error's message",
      "Error desconocido": 'Unknown error',
      "Datos enviados correctamente":"Data send correctly",
      "Solicitar": 'Request',
      "Para solicitar este elemento por favor rellene el formulario a continuacion y presione la opcion de enviar. En breve sera atendido.": 'To request this item please fill the form below and press the option "Send". In short you\'ll be attended',
      "Nombre Completo": "Full Name",
      "Cancelar": "Cancel",
      "Dirección": "Address",
      "Número de teléfono": "Telephone Number",
      "Correo Electrónico": "Email",
      "Get The Product": "Obtener el producto",
      "No hay datos en el servidor": "There is no data in the server"
    }
  },
  es: {
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;

export const _ = i18n.t;