import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { BaseURL } from "./apiConfig"
import { object } from "prop-types"

export const GalleriesInfo = {
  id: Number,
  image: String,
  location: String,
  categories: object
}
export const GalleriesResponse = {
  total: Number,
  totalNotFiltered: Number,
  rows: object
}

export const galleriesApi = createApi({
  reducerPath: 'galleriesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL
  }),
  endpoints: (builder) => ({
    getAllGalleries: builder.query({
      query: () => ({url:"/products/galleries/"})
    })
    
  })
})

export const { useGetAllGalleriesQuery } = galleriesApi